import { Grid, GridCol } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text } from '@naf/text';
import styled from 'styled-components';

export const FeatureHeaderWrapper = styled.div`
  margin: 0 -${spacing.space80};

  @media (max-width: ${breakpoints.xl}) {
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin: 0 -${spacing.space24};
  }
`;

export const BreadcrumbWrapper = styled.div`
  margin: -${spacing.space56} auto ${spacing.space40} auto;
  z-index: 10;
  display: flex;

  @media (max-width: ${breakpoints.l}) {
    margin: -${spacing.space32} auto ${spacing.space16} ${spacing.space16};
  }

  @media (max-width: ${breakpoints.m}) {
    margin: -${spacing.space8} auto ${spacing.space8} ${spacing.space16};
  }
`;

export const HeaderContentGridCol = styled(GridCol)`
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export const HeaderWrapperContentGrid = styled(Grid)`
  margin-bottom: ${spacing.space48};
  grid-row-gap: 0;

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ContentCol = styled.div`
  /* Firefox overflow bugfix */
  max-width: 100%;
  grid-column: 3 / -3;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

export const HeadingText = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space16};
`;

export const Ingress = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space40};

  @media (max-width: ${breakpoints.m}) {
    font-size: 1.25rem;
  }
`;

export const MainImageGridCol = styled.div`
  grid-column: 2 / -2;

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
    margin-left: -32px;
    margin-right: -32px;
  }
`;

export const VideoWrapper = styled.div<{ hasCaption?: boolean }>`
  margin: 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  ${(props) => (props.hasCaption ? `margin-bottom: ${spacing.space16}` : `margin-bottom: ${spacing.space80}`)};

  @media (max-width: ${breakpoints.m}) {
    ${(props) => (props.hasCaption ? `margin-bottom: ${spacing.space16}` : `margin-bottom: ${spacing.space48}`)};
  }

  video {
    outline: none;
  }

  svg {
    color: #1a1a1a;
    position: absolute;
    width: 60px;
    height: 60px;
    top: calc(50% - 30.5px);
    left: calc(50% - 48.5px);
    background-color: rgb(255 255 255 / 80%);
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 18px;
  }

  &:hover {
    svg {
      background-color: rgb(255 255 255 / 100%);
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: calc(100% + 48px);
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};

    svg {
      margin-left: -32px;
      width: 30px;
      height: 30px;
      top: calc(50% - 24.5px);
      left: calc(50% - 24.5px);
      padding: 9px;
    }
  }
`;

export const Caption = styled.div`
  line-height: 18px;

  .caption-header {
    display: inline;
    margin-right: ${spacing.space4};
  }
`;

export const ImageFigure = styled.figure<{ $hasCaption?: boolean }>`
  margin: 0;
  ${(props) => (props.$hasCaption ? `margin-bottom: ${spacing.space16}` : `margin-bottom: 0}`)};
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }

  @media (max-width: ${breakpoints.m}) {
    ${(props) => (props.$hasCaption ? `margin-bottom: ${spacing.space16}` : `margin-bottom: ${spacing.space48}`)};
  }
`;

export const BylineRole = styled.span`
  font-style: italic;
`;

export const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
  width: 96px;
  margin: ${spacing.space4} 0 ${spacing.space48};
  color: ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
`;
