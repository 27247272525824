import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonVariant } from '@naf/button';
import React from 'react';
import { useLocation } from 'react-router';
import { MEMBERPAGES_URL } from '../../lib/constants';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';

interface Props {
  buttonText?: string;
  returnUrl?: string;
  variant?: ButtonVariant;
}

export const LoginButton = ({ buttonText, returnUrl, variant }: Props) => {
  const { loginWithRedirect } = useAuth0();
  const returnToUrl = returnUrl || `/${MEMBERPAGES_URL}`;
  const dataLayer = useGTMDataLayer();
  const location = useLocation();

  const handleTracking = () => {
    dataLayer?.push({
      event: 'cta_click',
      cta_text: 'Logg inn',
      cta_location: location.pathname,
    });
  };

  return (
    <Button
      onClick={() => {
        handleTracking();
        loginWithRedirect({ appState: { returnTo: returnToUrl } });
      }}
      variant={variant}
    >
      {buttonText || 'Logg inn'}
    </Button>
  );
};
