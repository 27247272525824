import React from 'react';
import * as S from './Styles';
import MobileLights from '../../../../../../assets/decorations/christmas/MobileLights.svg';
import Lights from '../../../../../../assets/decorations/christmas/Lights.svg';
import SmallSnow from '../../../../../../assets/decorations/christmas/SmallSnow.svg';
import LargeSnow from '../../../../../../assets/decorations/christmas/LargeSnow.svg';

export const Christmas = ({ isScrolled }: { isScrolled?: boolean }) => (
  <S.ChristmasWrapper $isScrolled={isScrolled}>
    <S.ScaledContent $isScrolled={isScrolled}>
      <S.LightsWrapper>
        <S.MobileWrapper>
          <MobileLights />
        </S.MobileWrapper>
        <S.DesktopWrapper>
          <Lights />
        </S.DesktopWrapper>
      </S.LightsWrapper>
      <S.SnowContainer>
        <S.SmallSnowWrapper>
          <SmallSnow />
        </S.SmallSnowWrapper>
        <S.LargeSnowWrapper>
          <LargeSnow />
        </S.LargeSnowWrapper>
      </S.SnowContainer>
    </S.ScaledContent>
  </S.ChristmasWrapper>
);
