import styled from 'styled-components';
import { Text } from '@naf/text';

const ShowMoreButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;

  p {
    margin-left: 20px !important;
  }
`;

const ListHeader = styled(Text)`
  margin-bottom: 0;
  margin-top: 46px;
`;

const ElementRelatedCarList = styled.ul`
  padding-left: 0;
  margin-top: 5px;
`;

const DropdownWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin: -40px 0 0;
`;

const ElementRelatedCarListChild = styled.li`
  list-style-type: none;
  padding-top: 24px;

  a {
    padding-bottom: 24px !important;
  }
`;

const ElementInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h3 {
    width: auto;
    position: relative;
    margin: 0;
    margin-bottom: 16px;

    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: #ffdb16;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
`;

const ElementSubInfo = styled.p`
  font-size: 1rem;
  margin: 0 0 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 24px;
  flex-direction: column;

  svg {
    margin-right: 13px;
    padding: 0;
  }
`;

const IconSet = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 10px;

  b {
    font-weight: 500;
  }
`;

const RoadIconText = styled.b``;

export default {
  ListHeader,
  ElementRelatedCarList,
  DropdownWrapper,
  ElementRelatedCarListChild,
  ShowMoreButtonWrapper,
  ElementSubInfo,
  IconSet,
  IconWrapper,
  ElementInfo,
  RoadIconText,
};
