import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';

export const DesktopMenuWrapper = styled.div<{ $isMenuOpen: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      padding-left: ${spacing.space16};
    `}

  span {
    display: flex;
    align-items: center;
    transition: font-size 0.3s ease-in-out;
    text-decoration: none;
    padding: 2px 8px;
    border-radius: ${spacing.space4};
    color: ${nafColor.signature.black} !important;
    line-height: 1.5;

    :hover {
      text-decoration: none;
      background-color: ${nafColor.signature.yellow40};
      border-radius: ${spacing.space4};
    }
  }
`;

export const MenuButtonIcon = styled.div`
  margin-left: ${spacing.space4};

  svg {
    transition: all 0.3s ease-in-out;
  }
`;
