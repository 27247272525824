import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import { TextVariant } from '@naf/text';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AccountCircle } from '@styled-icons/material-outlined/AccountCircle';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';
import { actions as userActions } from '../../../redux/modules/user';
import { ProfileMenu } from '../../ProfileMenu/ProfileMenu';
import { MEMBERPAGES_URL } from '../../../lib/constants';
import { NavigationButtons } from '../../megamenu-overlay/NavigationButtons/NavigationButtons';
import * as S from './Styles';

const MemberArea = ({
  accountName,
  isScrolled,
  onProfileMenuClick,
  profileMenuRef,
  showProfileMenu,
  setShowProfileMenu,
  isAuthenticated,
  isMyNafEnabled,
  isLoading,
}: {
  accountName: string;
  isScrolled: boolean;
  isMyNafEnabled: boolean;
  isLoading: boolean;
  onProfileMenuClick: () => void;
  profileMenuRef: React.RefObject<HTMLDivElement>;
  showProfileMenu: boolean;
  setShowProfileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
}) => {
  const { loginWithRedirect } = useAuth0();
  const dataLayer = useGTMDataLayer();
  const dispatch = useDispatch();
  const location = useLocation();

  const performLoginAction = () => {
    dataLayer?.push({
      event: 'navbarClick',
      menuPage: location.pathname,
      menuSectionName: 'navbarMenuClick',
      menuItemName: 'Logg inn',
      menuItemUrl: 'mitt-naf',
    });
    dispatch(userActions.closeMenu());

    loginWithRedirect({
      appState: { returnTo: `/${MEMBERPAGES_URL}` },
    });
  };

  const handleLoginClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    performLoginAction();
  };

  const handleKeyPress: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      performLoginAction();
    }
  };

  return (
    <S.Container>
      {!isAuthenticated && !isLoading && isMyNafEnabled && (
        <S.LoginButton
          to={`/${MEMBERPAGES_URL}`}
          onClick={handleLoginClick}
          onKeyDown={handleKeyPress}
          className="loginButton"
        >
          <S.LoginText variant={TextVariant.ButtonSmall}>Logg inn</S.LoginText>
        </S.LoginButton>
      )}
      {isAuthenticated && isMyNafEnabled && (
        <S.ProfileMenuWrapper ref={profileMenuRef} $isScrolled={isScrolled}>
          <S.TopBarItemButton aria-label="Profilmeny" onClick={onProfileMenuClick} className="profile-menu">
            <AccountCircle size={24} />
            {accountName && <span>{accountName}</span>}
          </S.TopBarItemButton>
          {showProfileMenu && <ProfileMenu isScrolled={isScrolled} onClick={() => setShowProfileMenu(false)} />}
        </S.ProfileMenuWrapper>
      )}
      <S.ButtonWrapper>
        <NavigationButtons isLoggedIn={isAuthenticated} isScrolled={isScrolled} />
      </S.ButtonWrapper>
    </S.Container>
  );
};

export default MemberArea;
