import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { ExpandMore } from '@styled-icons/material/ExpandMore';
import { Close } from '@styled-icons/material/Close';
import * as S from './Styles';

interface DesktopMenuButtonProps {
  onClick?: () => void;
  isMenuOpen?: boolean;
  isScrolled?: boolean;
}

export const DesktopMenuButton: React.FC<DesktopMenuButtonProps> = ({ onClick, isMenuOpen, isScrolled }) => {
  const buttonVariant = isScrolled ? TextVariant.ButtonSmall : TextVariant.Button;
  const buttonText = isMenuOpen ? 'Lukk' : 'Mer';
  const iconSize = isScrolled ? 18 : 24;
  const buttonIcon = isMenuOpen ? <Close size={iconSize} /> : <ExpandMore size={iconSize} />;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <S.DesktopMenuWrapper onClick={onClick} onKeyDown={handleKeyDown} $isMenuOpen={isMenuOpen}>
      <Text tabIndex={0} tag="span" variant={buttonVariant}>
        {buttonText}
        <S.MenuButtonIcon>{buttonIcon}</S.MenuButtonIcon>
      </Text>
    </S.DesktopMenuWrapper>
  );
};
