import React from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing, breakpoints } from '@naf/theme';
import BlockContent from '../../../block-content/BlockContent';

import { TextAndBackgroundImageBlock } from '../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';

export const ParallaxItem = ({
  item,
  bgImage1,
  bgImage1Height,
}: {
  item: TextAndBackgroundImageBlock;
  bgImage1: string;
  bgImage1Height: string;
}) => (
  <Section key={item._key} className="section" id={item.title}>
    <ParallaxText className="sectionText" data-bg={item?.colorScheme} data-image-id={item._key}>
      {item.textBlock && <BlockContent value={item.textBlock} />}
    </ParallaxText>
    <BackgroundLayer className="backgroundLayer" $backgroundImg={`url(${bgImage1})`} $imgHeight={`${bgImage1Height}`} />
  </Section>
);

const Section = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-end;
  width: calc(100vw - 96px);
  max-width: 1120px;
  height: 100vh;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &:first-child {
    height: 0;
  }

  @media (max-width: ${breakpoints.l}) {
    width: calc(100vw - 48px);
  }
  @media (max-width: ${breakpoints.s}) {
    background-size: auto 100vh;
    width: 100vw;
  }
`;

const BackgroundLayer = styled.div<{ $backgroundImg?: string; $imgHeight?: string }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: calc(100vw - 96px);
  max-width: 1120px;
  ${(props) => (props.$backgroundImg ? `background-image: ${props.$backgroundImg};` : 'background-image: none;')}
  ${(props) => (props.$imgHeight ? `height: ${props.$imgHeight};` : 'height: calc(100vh - 96px);')}
  background-repeat: no-repeat;
  background-size: contain; //auto auto;
  background-position: bottom;
  @media (max-width: ${breakpoints.l}) {
    width: calc(100vw - 48px);
    ${(props) => (props.$imgHeight ? `height: ${props.$imgHeight};` : 'height: calc(100vh - 48px);')}
  }
  @media (max-width: ${breakpoints.s}) {
    background-size: contain;
    width: 100vw;
    background-size: cover;
    min-width: 100vw;
    ${(props) => (props.$imgHeight ? `height: ${props.$imgHeight};` : 'height: 100vh;')}
  }
`;

const ParallaxText = styled.div`
  z-index: 3;
  background: ${nafColor?.primary?.dew || '#E9F3E6'};
  display: inline-block;
  max-width: 576px;
  margin: ${spacing.space64};
  padding: ${spacing.space32};
  border-radius: 4px;
  color: ${nafColor?.signature?.black || '#1A1A1A'};
  p,
  h2,
  h3 {
    color: ${nafColor?.signature?.black || '#1A1A1A'};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${nafColor?.signature?.black || '#1A1A1A'};

    &:hover {
      color: ${nafColor?.primary?.park || '#267C4F'};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space48} ${spacing.space16};
  }
`;
