import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useGTMDataLayer } from './useGTMDataLayer';
import useSelector from '../redux/typedHooks';

export const useVirtualPageViewTracking = () => {
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const [currentTitle, setCurrentTitle] = useState<undefined | string>();
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const user = useSelector((state) => state.myMembership.customerInformation.data);

  useEffect(() => {
    // send user data to GTM on every page if user is logged in
    if (dataLayer && user?.customer && user.membership && currentTitle && currentTitle !== '') {
      dataLayer.push({
        // eslint-disable-next-line camelcase
        user_id: user.customerId,
        userType: user.customer.displayRoleName as string,
        hasMembership: user.membership.isValid,
        isMainMember: user.customer.customerRoleName === 'MainHouseHoldMember',
        isHouseMember: user.customer.customerRoleName !== 'MainHouseHoldMember',
        hasRoadSideAssistance: !!user.membership.roadSideAssistance,
      });
    }
  }, [user, dataLayer, currentTitle]);

  useEffect(() => {
    if (dataLayer && baseUrl && currentTitle && currentTitle !== '') {
      dataLayer?.push({
        event: 'virtualPageView',
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}${location.search}`,
        pageTitle: currentTitle || 'UNKNOWN',
      });
    }
    // disable eslint warning because we want to run this only when the currentTitle changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTitle, dataLayer, baseUrl]);

  // Set up a listener to update page title state on navigation, and set current title after observer is set up.
  useEffect(() => {
    if (typeof document !== 'undefined' && !!document) {
      const observer = new MutationObserver((mutations: MutationRecord[]) => {
        if (mutations[0].target.textContent) {
          setCurrentTitle(mutations[0].target.textContent);
        }
      });
      const titleElement = document.querySelector('title');
      if (titleElement) {
        observer.observe(titleElement, {
          subtree: true,
          characterData: true,
          childList: true,
        });
        setCurrentTitle(document.title);
        return () => observer.disconnect();
      }
      setCurrentTitle(document.title);
    }
    return () => {};
  }, []);
};
