import React from 'react';
import ConstitutionDayDesktop from '../../../../../../assets/decorations/constitutionDay/ConstitutionDayDesktop.svg';
import ConstitutionDayMobile from '../../../../../../assets/decorations/constitutionDay/ConstitutionDayMobile.svg';
import * as S from './Styles';

export const ConstitutionDay = ({ isScrolled }: { isScrolled?: boolean }) => (
  <>
    <S.DesktopWrapper $isScrolled={isScrolled}>
      <ConstitutionDayDesktop />
    </S.DesktopWrapper>
    <S.MobileWrapper>
      <ConstitutionDayMobile />
    </S.MobileWrapper>
  </>
);
