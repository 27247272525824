export const MAIN_URL = 'https://www.naf.no';
export const MEMBERPAGES_URL = 'mitt-naf';
export const DEFAULT_IMG_URL =
  'https://res.cloudinary.com/nafmedier/image/upload/v1694692540/Logo/LogoNAF_480x480_ijk3ga.png';

export const DEFAULT_IMG_PUBLIC_ID = 'v1694692540/Logo/LogoNAF_480x480_ijk3ga.png';

// Navbar links
export const HOME = '/';
export const MEMBERSHIP = '/medlemskap';
export const NAFCENTER = '/bilverksted-og-tester';
export const ORDER_ROADSIDE_URL = 'https://naf-veihjelp.sos.eu/order-rsa';

// TODO: Change ERROR_IMG_URL to some NAF Error image
export const MAIN_TITLE = 'NAF - Norges Automobil-Forbund';
export const MAIN_DESCRIPTION =
  'Vi tilbyr bilberging/veihjelp i hele Europa, rådgiving, NAF -tester, advokat hjelp, EU kontroll og rabatter. Bli NAF-medlem her.';
export const feedbackLabel = 'Kan du utdype hvorfor du gir dette svaret?';

export default {
  MAIN_URL,
  MEMBERPAGES_URL,
  DEFAULT_IMG_URL,
  HOME,
  MEMBERSHIP,
  feedbackLabel,
};

// Used for validation of license plate number.
export const minimumLicensePlateLength = 4;
