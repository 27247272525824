import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { Loader } from '@naf/loader';
import { nafColor } from '@nafcore/theme';
import { Input } from '@naf/input';
import { ButtonLink } from '@naf/button-link';
import { GridCol, GridRow } from '@naf/grid';
import { Link } from 'react-router-dom';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { ProductType, useProductChangeCalculator } from '../../../../../hooks/useProductChangeCalculator';
import { useSessionStorage } from '../../../../../hooks/useSessionStorage';
import { OilChangeCalculations } from '../../../../../../../types/oilChangeCalculationTypes';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';

export const OilChangeCalculatorBlock = () => {
  const [centerId] = useSessionStorage('centerId', undefined);
  const [licensePlateNumber, setLicensePlateNumber] = useState<string>('');
  const [calculationResult, setCalculationResult] = useState<OilChangeCalculations | undefined>();
  const { calculate, calculation, isLoading, error } = useProductChangeCalculator({
    productType: ProductType.OILCHANGE,
    licensePlateNumber,
    centerId: centerId as string | undefined,
  });

  useEffect(() => {
    setCalculationResult(calculation as OilChangeCalculations);
  }, [calculation]);

  return (
    <OilChange>
      <Text variant={TextVariant.Header2}>Se hvor mye oljeskift på din bil koster</Text>
      <Text variant={TextVariant.ArticleTextLarge}>
        Legg inn registeringsnummer for å beregne hvor mye oljeskiftet koster for din bil.
      </Text>
      <Search>
        <Input
          placeholder="DN12345"
          id="registrationNumber"
          type="text"
          onChange={(number: string) => setLicensePlateNumber(number)}
          onKeyUp={(e: KeyboardEvent) => {
            if (e.key === 'Enter') calculate();
          }}
        />
        <Button type="button" variant="secondary" onClick={() => calculate()}>
          Beregn pris
        </Button>
      </Search>
      {isLoading && (
        <SpinnerWrapper>
          <Loader />
        </SpinnerWrapper>
      )}
      {error && !isLoading && (
        <LookupResultError>
          <Text variant={TextVariant.Header3}>Vi klarte ikke finne en bil på dette registreringsnummeret.</Text>
          <Text>Ta kontakt med kundeservice for en prisforespørsel på oljeskift for ditt kjøretøy.</Text>
          <ButtonLink as={Link} to="/kundeservice" text="Kontakt kundeservice" />
        </LookupResultError>
      )}
      {!error && !isLoading && calculationResult && (
        <LookupResultSuccess>
          <CheckCircle size={20} />
          {calculationResult.vehicle && (
            <SuccessText variant={TextVariant.BodyText}>
              {calculationResult.vehicle?.manufactureName} {calculationResult.vehicle?.modelName}{' '}
              {calculationResult.vehicle?.year}
            </SuccessText>
          )}
          {calculationResult.oilChange && calculationResult.oilChange.litersOfOil && (
            <Summary>
              <Text variant={TextVariant.Header3}>
                Din pris på oljeskift: {calculationResult.oilChange?.totalPriceForNonMembers},-
              </Text>
              <SummaryRow>
                <GridCol s="8" m="8" l="8" xl="8">
                  <Text variant={TextVariant.ArticleTextHeader}>
                    {calculationResult.oilChange?.litersOfOil} liter olje
                  </Text>
                </GridCol>
                <GridCol s="4" m="4" l="4" xl="4">
                  <Text>{calculationResult.oilChange?.pricePerLiterOfOil},-/liter</Text>
                </GridCol>
              </SummaryRow>
              <SummaryRow>
                <GridCol s="8" m="8" l="8" xl="8">
                  <Text variant={TextVariant.ArticleTextHeader}>Arbeid</Text>
                </GridCol>
                <GridCol s="4" m="4" l="4" xl="4">
                  <Text>{calculationResult.oilChange?.laborPriceForNonMembers},-</Text>
                </GridCol>
              </SummaryRow>
              <SummaryRow>
                <GridCol s="8" m="8" l="8" xl="8">
                  <Text variant={TextVariant.ArticleTextHeader}>Oljefilter</Text>
                </GridCol>
                <GridCol s="4" m="4" l="4" xl="4">
                  <Text>{calculationResult.oilChange?.oilFilterPrice},-</Text>
                </GridCol>
              </SummaryRow>
            </Summary>
          )}
          {!calculationResult.oilChange?.litersOfOil &&
            calculationResult.vehicle &&
            (calculationResult.vehicle.isElectric ? (
              <>
                <Text variant={TextVariant.Header3}>Det ser ikke ut som din bil er aktuell for oljeskift</Text>
                <Text>
                  Har det skjedd en feil? Ta kontakt med kundeservice for en prisforespørsel på oljeskift for ditt
                  kjøretøy
                </Text>
                <ButtonLink as={Link} to="/kundeservice" text="Kontakt kundeservice" />
              </>
            ) : (
              <>
                <Text variant={TextVariant.Header3}>Vi klarer ikke å kalkulere riktig pris for din bil</Text>
                <Text>Ta kontakt med kundeservice for en prisforespørsel på oljeskift for ditt kjøretøy</Text>
                <ButtonLink as={Link} to="/kundeservice" text="Kontakt kundeservice" />
              </>
            ))}
          {calculationResult.link && calculationResult.link.data && (
            <Button
              href={calculationResult.link.data.href}
              target={calculationResult.link.data.blank ? '_blank' : '_self'}
            >
              Finn ledig tid
            </Button>
          )}
        </LookupResultSuccess>
      )}
    </OilChange>
  );
};

export const OilChangeCalculatorSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <OilChangeCalculatorBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <OilChangeCalculatorBlock />;
};

const OilChange = styled.div`
  margin-bottom: ${spacing.space12};

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: 0;
  }

  h2 {
    margin: 0 0 ${spacing.space8} !important;
  }
  p {
    margin: 0 !important;
  }
`;

const Search = styled.div`
  display: flex;
  margin-top: ${spacing.space32};

  button {
    margin-left: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space20};
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space16};
    button {
      margin-left: ${spacing.space8};
    }
    > div {
      flex-grow: 1;
    }
  }
`;

const LookupResultSuccess = styled.div`
  margin-top: ${spacing.space8};

  svg {
    color: ${nafColor.information.successDark};
  }
  a {
    margin-top: ${spacing.space16};
  }
`;

const LookupResultError = styled.div`
  margin-top: ${spacing.space40};

  h3 {
    margin: 0 0 ${spacing.space8};
  }
  p {
    margin: 0;
  }
  a {
    margin-top: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space32};
  }
`;

const SuccessText = styled(Text)`
  display: inline-flex;
  color: ${nafColor.information.successDark};
  margin-left: ${spacing.space8} !important;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.space24} 0;
  max-width: 256px;

  h3 {
    margin: 0 0 ${spacing.space8};
  }

  h4,
  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    margin: ${spacing.space20} 0;
    max-width: 100%;
  }
`;

const SummaryRow = styled(GridRow)`
  padding: ${spacing.space16} 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  grid-gap: 0 !important;

  h5 {
    padding-right: ${spacing.space32};
  }
`;

const SpinnerWrapper = styled.div`
  margin-top: ${spacing.space24};
  width: fit-content;

  @media (max-width: ${breakpoints.s}) {
    margin-left: auto;
    margin-right: auto;
  }
`;
