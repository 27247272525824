import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { ArticleSeries } from '../../../../types/articleType';
import ArticleSeriesItem from './ArticleSeriesItem';

type Props = {
  articleSeries: ArticleSeries;
};

const ArticleSeriesFooter = ({ articleSeries }: Props) => (
  <Wrapper>
    {articleSeries.items.map((article) => (
      <ArticleSeriesItem key={article.id} article={article} size="large" />
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / -2;
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;
export default ArticleSeriesFooter;
