import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Text, TextVariant } from '@naf/text';
import { Close } from '@styled-icons/material/Close';
import { actions } from '../../redux/modules/globalSettings';
import { actions as userActions } from '../../redux/modules/user';
import BlockContent from '../block-content/BlockContent';
import { GlobalSettingsType } from '../../../../types/globalSettingsType';
import useSelector from '../../redux/typedHooks';
import S from './StyledSystemMessage';

const SystemMessage = () => {
  const dispatch = useDispatch();

  const globalSettings: GlobalSettingsType | undefined = useSelector((state) => state.globalSettings?.settings);

  const { showSystemMessage } = useSelector((state) => state.user);

  useEffect(() => {
    if (typeof globalSettings === 'undefined') {
      dispatch(actions.getGlobalSettings.request(''));
    }
  }, [dispatch, globalSettings]);

  useEffect(() => {
    if (typeof globalSettings !== 'undefined' && globalSettings?.announcement) {
      dispatch(userActions.showSystemMessage(true));
    }
  }, [globalSettings, dispatch]);

  const handleCloseSystemMessage = () => {
    dispatch(userActions.showSystemMessage(false));
    return false;
  };

  const message = globalSettings?.announcement;

  return message && showSystemMessage ? (
    <S.MessageWrapper id="system-message">
      <S.InnerWrapper>
        {message.header && <Text variant={TextVariant.ArticleTextHeader}>{message.header}</Text>}
        {message.body && <BlockContent value={message.body} />}
        <S.CloseButton onClick={() => handleCloseSystemMessage()} aria-label="Lukk melding">
          <Close size={24} />
        </S.CloseButton>
      </S.InnerWrapper>
    </S.MessageWrapper>
  ) : null;
};

export default SystemMessage;
