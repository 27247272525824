import React, { ReactElement } from 'react';
import useSelector from '../../../redux/typedHooks';
import { NafBirthday } from './Designs/NafBirthday/NafBirthday';
import { useCloudinary } from '../../../hooks/useCloudinary';
import * as S from './Styles';
import { ConstitutionDay } from './Designs/ConstitutionDay/ConstitutionDay';

interface LogoProps {
  onNavClick: () => void;
  isScrolled?: boolean;
}

export const Logo = ({ onNavClick, isScrolled }: LogoProps) => {
  const cld = useCloudinary();
  const logo = useSelector((state) => state.globalSettings.settings?.logo);
  const decorationToShow = useSelector((state) => state.globalSettings.settings?.decoration);

  const defaultLogoImage =
    (logo && logo.publicId && cld.image(logo.publicId).quality('auto:best').format('svg').toURL()) || undefined;

  let decorationComponent: ReactElement | null;
  switch (decorationToShow) {
    case 'nafBirthday':
      decorationComponent = <NafBirthday isScrolled={isScrolled} />;
      break;
    case 'constitutionDay':
      decorationComponent = <ConstitutionDay isScrolled={isScrolled} />;
      break;
    case 'none':
    default:
      decorationComponent = <S.Logo $isScrolled={isScrolled} src={defaultLogoImage} alt="Naf logo" />;
      break;
  }

  return (
    <S.StyledLink to="/" onClick={onNavClick} style={{ outline: 'none' }}>
      {decorationComponent}
    </S.StyledLink>
  );
};
