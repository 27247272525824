import React from 'react';
import { PortableText } from '@portabletext/react';
import { marks } from './components/marks';
import { types } from './components/types';
import { list } from './components/list';
import { ContainerSerializer } from './components/Container';
import { block } from './components/types/components/BlockSerializer';
import { StyledArticleContentGrid } from '../../pages/Article';

const BlockContent = ({ value, context }: { value?: any; context?: 'article' | 'stripped' }) => {
  const components = React.useMemo(
    () => ({
      types: types(context),
      list: list(context),
      block: block(context),
      marks,
      container: ContainerSerializer(context),
    }),
    [context],
  );

  const filteredComponents = React.useMemo(
    () =>
      value?.filter(
        (component: any) =>
          [...Object.keys(components.types), 'block', 'span'].includes(component._type) &&
          typeof component._type !== 'undefined',
      ),
    [value, components.types],
  );

  if (!filteredComponents || filteredComponents.length === 0) {
    return null;
  }

  return context === 'article' ? (
    <StyledArticleContentGrid>
      <PortableText value={filteredComponents} components={components} />
    </StyledArticleContentGrid>
  ) : (
    <PortableText value={filteredComponents} components={components} />
  );
};

export default BlockContent;
