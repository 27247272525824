import styled, { css } from 'styled-components';
import { radius, spacing, breakpoints, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

export const ProfileMenuList = styled.ul<{ $isScrolled: boolean }>`
  min-width: 154px;
  position: absolute;
  list-style: none;
  background-color: ${nafColor.signature.white};
  filter: drop-shadow(0 2px 12px rgb(0 0 0 / 24%));
  padding: ${spacing.space20} ${spacing.space12};
  border-radius: ${radius.s};
  right: -10px;
  top: ${spacing.space32};
  z-index: 100;
  transition: top 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      top: ${spacing.space24};
    `}

  li:first-child {
    p {
      margin-top: 0;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    top: 28px;
  }
`;

export const ProfileMenuItem = styled.li`
  margin-bottom: -${spacing.space4};
  cursor: pointer;

  p {
    display: flex;
    margin: 0;
  }

  svg {
    margin-right: ${spacing.space8};
  }
`;

export const StyledButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: ${spacing.space4} ${spacing.space8};
  color: ${themeLight.typography.brandText} !important;

  :hover {
    text-decoration: none;
    background-color: ${nafColor.signature.yellow40};
    border-radius: ${spacing.space4};
  }
`;
