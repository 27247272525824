import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { MenuButton } from '@naf/menu-button';
import { Link } from 'react-router-dom';
import { neutral1Border } from '../megamenu-overlay/Styles';

// Desktop
export const Overlay = styled.div<{ $isMenuOpen?: boolean }>`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(6 46 42 / 50%);
  z-index: 80;

  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `};

  @media (max-width: ${breakpoints.s}) {
    display: none !important;
  }
`;

export const DecorationWrapper = styled.div<{ $isMenuOpen?: boolean }>`
  position: absolute;
  display: flex;
  z-index: 91;
  pointer-events: none;

  @media (max-width: ${breakpoints.l}) {
    ${({ $isMenuOpen }) =>
      $isMenuOpen &&
      css`
        display: none;
      `}
  }
`;

export const Wrapper = styled.header<{ $isMenuOpen?: boolean; $isSearchOpen?: boolean }>`
  display: flex;
  justify-content: center;
  height: 0;
  position: sticky;
  top: 0;
  z-index: 90;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0 ${spacing.space16};

  ${({ $isSearchOpen }) =>
    $isSearchOpen &&
    css`
      background-color: ${nafColor?.signature.white || '#FFF'};
    `}

  a {
    color: ${nafColor.signature.black} !important;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : nafColor.signature.black)};
    }
  }

  @media (max-width: ${breakpoints.l}) {
    background-color: ${({ $isSearchOpen, $isMenuOpen }) => {
      if ($isSearchOpen) {
        return nafColor?.signature.white || '#FFF';
      }
      if ($isMenuOpen) {
        return nafColor?.signature.yellow10 || '#FFFAEC';
      }
      if ($isSearchOpen && $isMenuOpen) {
        return nafColor?.signature.white || '#FFF';
      }
      return 'transparent';
    }};
  }

  @media (max-width: ${breakpoints.m}) {
    min-height: auto;
  }
`;

export const Container = styled.div<{ $isScrolled: boolean; $isSearchOpen: boolean; $isMenuOpen: boolean }>`
  background-color: transparent;
  max-width: 1400px;
  box-sizing: border-box;
  width: 100%;
  position: sticky;

  @media (max-width: ${breakpoints.l}) {
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.m}) {
    ${({ $isMenuOpen }) =>
      $isMenuOpen &&
      css`
        position: absolute;
      `}
    ${({ $isScrolled }) =>
      $isScrolled &&
      css`
        margin-top: 0;
      `}
    background-color: ${({ $isSearchOpen, $isMenuOpen }) => {
      if ($isSearchOpen) {
        return nafColor?.signature.white || '#FFF';
      }
      if ($isMenuOpen) {
        return nafColor?.signature.yellow10 || '#FFFAEC';
      }
      if ($isSearchOpen && $isMenuOpen) {
        return nafColor?.signature.white || '#FFF';
      }
      return 'transparent';
    }};
  }
`;

export const TopBar = styled.div<{
  $isScrolled: boolean;
  $isSearchOverlayOpen: boolean;
  $isMenuOpen: boolean;
}>`
  display: flex;
  padding: ${spacing.space16} ${spacing.space24};
  border-radius: ${spacing.space8};
  border: 1px solid ${nafColor.signature.yellow20};
  backdrop-filter: blur(15px);
  height: 38px;
  margin-top: ${spacing.space16};
  transition: height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  background-color: ${({ $isSearchOverlayOpen, $isMenuOpen }) => {
    if ($isSearchOverlayOpen) {
      return nafColor?.signature.white || '#FFF';
    }
    if ($isMenuOpen) {
      return nafColor?.signature.yellow10 || '#FFFAEC';
    }
    if ($isSearchOverlayOpen && $isMenuOpen) {
      return nafColor?.signature.white || '#FFF';
    }
    return 'rgba(255, 250, 236, 0.8)';
  }};

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 14px;
      margin-top: ${spacing.space8};
    `}

  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    `}

    ${({ $isSearchOverlayOpen }) =>
    $isSearchOverlayOpen &&
    css`
      border-top: ${neutral1Border};
      border-left: ${neutral1Border};
      border-right: ${neutral1Border};
      border-bottom: none;
    `};

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;

export const SearchbarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledMenuButton = styled(MenuButton)`
  z-index: 25;
  margin-left: ${spacing.space16} !important;

  div {
    color: ${nafColor.signature.black} !important;
  }

  /* This is needed to override the second div in the MenuButton component, since it does not conatin a span like the others. */
  div:nth-child(2) {
    background: ${nafColor.signature.black} !important;
  }

  span {
    background-color: ${nafColor.signature.black} !important;
  }

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${spacing.space8} !important;
  }
`;

export const TopBarItems = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.l}) {
    align-self: stretch;
    display: none;
  }

  @media (max-width: ${breakpoints.s}) {
    align-self: stretch;
  }
`;

export const ExternalNavbarLinks = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.xl}) {
    display: none;
  }
`;

export const TopBarItem = styled(Link)`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  color: ${nafColor.signature.black} !important;
  border-radius: ${spacing.space4};
  border: 1px solid transparent;

  &:hover {
    text-decoration: none;
    background-color: ${nafColor.signature.yellow40};
  }

  &:focus {
    border: 1px solid black !important;
  }

  span {
    margin: ${spacing.space4} ${spacing.space8};

    @media (max-width: ${breakpoints.m}) {
      margin: ${spacing.space4} ${spacing.space4};
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 0.875rem;
    }
  }

  &.profile-menu {
    margin: 0 -${spacing.space4} 0 0 !important;

    @media (max-width: ${breakpoints.s}) {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${spacing.space16} !important;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: row;
    justify-content: center;
    margin-left: ${spacing.space16} !important;
    margin-right: -${spacing.space4} !important;
    padding: 0 ${spacing.space4} !important;
  }
`;

export const CustomerField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

// Mobile
export const TopBarMobile = styled.div<{ $isLoggedIn?: boolean; $isMenuOpen: boolean; $isSearchOverlayOpen?: boolean }>`
  display: none;
  border-radius: ${spacing.space8};
  backdrop-filter: blur(15px);
  height: 40px;
  padding: ${spacing.space16} 0;
  transition: background 0.3s ease-out;
  margin-top: ${spacing.space12};

  @media (max-width: ${breakpoints.l}) {
    display: flex;
    flex-direction: column;
    padding: ${spacing.space8} ${spacing.space8};
    background-color: ${({ $isSearchOverlayOpen, $isMenuOpen }) => {
      if ($isSearchOverlayOpen) {
        return nafColor?.signature.white || '#FFF';
      }
      if ($isMenuOpen) {
        return nafColor?.signature.yellow10 || '#FFFAEC';
      }
      if ($isSearchOverlayOpen && $isMenuOpen) {
        return nafColor?.signature.white || '#FFF';
      }
      return 'rgba(255, 250, 236, 0.8)';
    }};

    ${({ $isMenuOpen }) =>
      $isMenuOpen &&
      css`
        flex-direction: column;
        height: 110px;
        backdrop-filter: blur(0);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
      `};

    @media (max-width: ${breakpoints.m}) {
      ${({ $isMenuOpen }) =>
        $isMenuOpen &&
        css`
          border-radius: 0;
        `}
    }
  }
`;

export const MobileMenuButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
`;

export const MobileTopBarInline = styled.div<{ $isMenuOpen: boolean }>`
  display: flex;
  height: 40px;

  @media (max-width: ${breakpoints.m}) {
    ${({ $isMenuOpen }) =>
      $isMenuOpen &&
      css`
        padding: 0 ${spacing.space16};
      `}
  }
`;
