import React from 'react';
import { useJsApiLoader, GoogleMap } from '@react-google-maps/api';
import useSelector from '../../redux/typedHooks';
import { Spinner } from '../spinner/Spinner';

type Props = {
  containerStyle?: { [k: string]: any };
  mapOptions: google.maps.MapOptions;
  children?: React.ReactNode;
  zoom?: number;
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  onLoad?: (map: google.maps.Map) => void | Promise<void>;
  onClick?: (e: google.maps.MapMouseEvent) => void;
};

const defaultContainerStyle = {
  width: '100%',
  height: '70vh',
};

const MapWrapper = ({ mapOptions, containerStyle, zoom, center, onLoad, onClick, children }: Props) => {
  const googleMapsApiKey = useSelector((state) => state.application.googleMapsApiKey);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
  });

  if (!isLoaded) return <Spinner />;
  if (loadError) return <div>Kartet kunne ikke lastes...</div>;
  return (
    <GoogleMap
      mapContainerStyle={containerStyle || defaultContainerStyle}
      zoom={zoom}
      center={center}
      onLoad={onLoad}
      onClick={onClick}
      options={mapOptions}
    >
      {children}
    </GoogleMap>
  );
};

export default MapWrapper;
