import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import loadable from '@loadable/component';
import { Loader } from '@naf/loader';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import useSelector from '../../redux/typedHooks';
import { actions } from '../../redux/modules/user';
import { actions as membershipActions } from '../../redux/modules/myMembership';
import { actions as menuActions } from '../../redux/modules/globalSettings';
import { actions as internalSearchActions } from '../../redux/modules/internalSiteSearch';
import { getURLParams } from '../../lib/getUrlParams';
import { useKeyboardEvent } from '../../hooks/useKeyboardEvent';
import { NavbarLink } from './NavbarLink/NavbarLink';
import { SearchBar } from '../SearchBar/Searchbar';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { DesktopMenuButton } from './DesktopMenuButton/DesktopMenuButton';
import { disableScroll, enableScroll } from '../../hooks/useScrollBlock';
import { Decorations } from './Decorations/Decorations';
import LogoArea from './LogoArea/LogoArea';
import MemberArea from './MemberArea/MemberArea';
import { SpinnerWrapper } from '../spinner/Styles';
import * as S from './Styles';

const MegaMenuOverlay = loadable(() => import('../megamenu-overlay/MegaMenuOverlay'));

const Navbar = () => {
  const searchInputText = useSelector((state) => state.internalSiteSearch.query);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [navOffset, setNavOffset] = useState(0);
  const history = useHistory();
  const { simpleToken } = useAuth0Token();
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const navBarRef = useRef<HTMLDivElement>(null);
  const navBarWrapperRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const { isMenuOpen, isSearchOverlayOpen, showSystemMessage, isSearchActive } = useSelector((state) => state.user);
  const isMyNAFEnabled = useSelector((state) => state.application.toggledFeatures.myNAF);
  const isSearchEnabled = useSelector((state) => state.application.toggledFeatures.search);
  const { customer } = useSelector((state) => state.myMembership?.customerInformation?.data);
  const profileMenu = useSelector((state) => state.globalSettings.settings?.profileMenu);
  const navbarItems = useSelector((state) => state.globalSettings.settings?.navigationBar?.items);
  const accountName =
    customer?.firstName && typeof customer.firstName === 'string' ? customer.firstName.split(' ')[0] : null;

  useEffect(() => {
    if (navBarRef?.current && showSystemMessage) {
      setNavOffset(navBarRef.current.getBoundingClientRect().top);
    }
  }, [showSystemMessage]);

  useEffect(() => {
    if (isAuthenticated && simpleToken && !customer?.customerId) {
      dispatch(membershipActions.getCustomerInformation.request(simpleToken));
    }
  }, [simpleToken, isAuthenticated, customer?.customerId, dispatch]);

  useEffect(() => {
    if (!profileMenu || profileMenu.length < 1) {
      dispatch(menuActions.getGlobalSettings.request(''));
    }
  }, [dispatch, profileMenu, navbarItems]);

  const onSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!searchInputText.trim()) return false;
      dispatch(internalSearchActions.getSearchResults.request());
      history.push({ pathname: history.location.pathname, search: `?search=${searchInputText}` });
      if (!isSearchOverlayOpen) dispatch(actions.toggleSearchOverlay(true));
      if (!isSearchActive) dispatch(actions.toggleSearchActive(true));
    }
    return false;
  };

  const resetSearch = () => {
    dispatch(internalSearchActions.reset());
    if (isSearchActive) dispatch(actions.toggleSearchActive(false));
    history.push({ pathname: history.location.pathname, search: `` });
  };

  const onCloseSearch = () => {
    resetSearch();
    dispatch(actions.toggleSearchOverlay(false));
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    dispatch(internalSearchActions.updateQuery(element.value));
  };

  useEffect(() => {
    const parsedParams = getURLParams();
    if (typeof parsedParams.search !== 'undefined' && parsedParams.search !== '') {
      dispatch(internalSearchActions.updateQuery(parsedParams.search));
      dispatch(internalSearchActions.getSearchResults.request());
      dispatch(actions.toggleSearchActive(true));
      dispatch(actions.toggleSearchOverlay(true));
      if (!isMenuOpen) {
        dispatch(actions.toggleMenu());
      }
    }
  }, [isSearchOverlayOpen, dispatch, isMenuOpen]);

  const onHamburgerMenuClick = () => {
    if (isSearchOverlayOpen) onCloseSearch();
    setShowProfileMenu(false);
    dispatch(actions.toggleMenu());
    if (!isMenuOpen) {
      disableScroll();
    } else {
      enableScroll();
    }
  };

  const onProfileMenuClick = () => {
    if (isMenuOpen) {
      dispatch(actions.closeMenu());
      enableScroll();
    }
    if (isSearchOverlayOpen) onCloseSearch();
    setShowProfileMenu(!showProfileMenu);
  };

  const onNavClick = () => {
    if (isMenuOpen) dispatch(actions.closeMenu());
    if (isSearchOverlayOpen) onCloseSearch();
    enableScroll();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!isMenuOpen) {
        const distance = window.scrollY;
        setIsScrolled(distance > 0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMenuOpen]);

  useOutsideAlerter(navBarWrapperRef, isMenuOpen && !isSearchActive, () => {
    if (isMenuOpen) {
      dispatch(actions.closeMenu());
      enableScroll();
    } else {
      disableScroll();
    }
  });

  useKeyboardEvent('Escape', () => {
    if (isSearchOverlayOpen) {
      onCloseSearch();
    } else if (isMenuOpen || showProfileMenu) {
      dispatch(actions.closeMenu());
      setShowProfileMenu(false);
      enableScroll();
    }
  });

  return (
    <>
      <S.Overlay $isMenuOpen={isMenuOpen} />
      <S.Wrapper
        data-elastic-exclude
        $isMenuOpen={isMenuOpen}
        ref={navBarWrapperRef}
        $isSearchOpen={isSearchOverlayOpen}
      >
        {/* Desktop */}
        <S.Container
          ref={navBarRef}
          $isScrolled={isScrolled}
          $isMenuOpen={isMenuOpen}
          $isSearchOpen={isSearchOverlayOpen}
        >
          <S.DecorationWrapper $isMenuOpen={isMenuOpen}>
            <Decorations isScrolled={isScrolled} />
          </S.DecorationWrapper>
          <S.TopBar
            className={isSearchActive ? 'searchActive' : 'searchInactive'}
            $isScrolled={isScrolled}
            $isSearchOverlayOpen={isSearchOverlayOpen}
            $isMenuOpen={isMenuOpen}
          >
            <LogoArea isScrolled={isScrolled} onNavClick={onNavClick} />
            {isSearchEnabled && isMenuOpen && (
              <S.SearchbarContainer>
                <SearchBar
                  isScrolled={isScrolled}
                  isMenuOpen={isMenuOpen}
                  onSearch={onSearch}
                  onSearchInputChange={onSearchInputChange}
                  searchInputRef={searchInputRef}
                />
                <DesktopMenuButton onClick={onHamburgerMenuClick} isMenuOpen={isMenuOpen} isScrolled={isScrolled} />
              </S.SearchbarContainer>
            )}
            {!isMenuOpen && (
              <S.TopBarItems>
                {navbarItems?.slice(0, 3).map((link) => (
                  <NavbarLink item={link} key={link.header} isScrolled={isScrolled} />
                ))}
                <S.ExternalNavbarLinks>
                  {navbarItems?.slice(3).map((link) => (
                    <NavbarLink item={link} key={link.header} isScrolled={isScrolled} />
                  ))}
                </S.ExternalNavbarLinks>
                <DesktopMenuButton onClick={onHamburgerMenuClick} isMenuOpen={isMenuOpen} isScrolled={isScrolled} />
              </S.TopBarItems>
            )}
            <S.CustomerField>
              {isLoading && !isAuthenticated ? (
                <SpinnerWrapper>
                  <Loader />
                </SpinnerWrapper>
              ) : (
                <MemberArea
                  accountName={accountName}
                  isScrolled={isScrolled}
                  onProfileMenuClick={onProfileMenuClick}
                  profileMenuRef={profileMenuRef}
                  showProfileMenu={showProfileMenu}
                  setShowProfileMenu={setShowProfileMenu}
                  isAuthenticated={isAuthenticated}
                  isMyNafEnabled={isMyNAFEnabled}
                  isLoading={isLoading}
                />
              )}
            </S.CustomerField>
          </S.TopBar>
          {/* Mobile */}
          <S.TopBarMobile $isMenuOpen={isMenuOpen} $isSearchOverlayOpen={isSearchOverlayOpen}>
            <S.MobileTopBarInline $isMenuOpen={isMenuOpen}>
              <LogoArea onNavClick={onNavClick} />
              <S.MobileMenuButtonsWrapper>
                {isLoading ? (
                  <SpinnerWrapper>
                    <Loader size="small" />
                  </SpinnerWrapper>
                ) : (
                  <MemberArea
                    accountName={accountName}
                    isScrolled={isScrolled}
                    onProfileMenuClick={onProfileMenuClick}
                    profileMenuRef={profileMenuRef}
                    showProfileMenu={showProfileMenu}
                    setShowProfileMenu={setShowProfileMenu}
                    isAuthenticated={isAuthenticated}
                    isMyNafEnabled={isMyNAFEnabled}
                    isLoading={isLoading}
                  />
                )}
                <S.StyledMenuButton ariaLabel="Hovedmeny" onClick={onHamburgerMenuClick} isOpen={isMenuOpen} />
              </S.MobileMenuButtonsWrapper>
            </S.MobileTopBarInline>
            {isSearchEnabled && (
              <SearchBar
                isMenuOpen={isMenuOpen}
                onSearch={onSearch}
                onSearchInputChange={onSearchInputChange}
                searchInputRef={searchInputRef}
              />
            )}
          </S.TopBarMobile>
          {isMenuOpen && (
            <MegaMenuOverlay
              isSearchActive={isSearchActive}
              isLoggedIn={isMyNAFEnabled && isAuthenticated}
              menuOffset={navOffset}
              refProp={navBarRef}
            />
          )}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Navbar;
