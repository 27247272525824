import React, { MouseEventHandler, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GridCol, GridRow } from '@naf/grid';
import { Button } from '@naf/button';
import { Text, TextVariant } from '@naf/text';
import { ThemeProvider } from '@naf/theme-provider';
import { themeLight } from '@naf/theme';
import { Link, useLocation } from 'react-router-dom';
import { useCloudinary } from '../../hooks/useCloudinary';
import { actions } from '../../redux/modules/globalSettings';
import useSelector from '../../redux/typedHooks';
import InstagramIcon from '../icons/Instagram';
import FacebookIcon from '../icons/Facebook';
import LinkedInIcon from '../icons/LinkedIn';
import TwitterIcon from '../icons/Twitter';
import S from './styles';
import { becomeAMemberPath, becomeAMemberIfYouHaveSmallKidsPath } from '../ThemedApp';
import { MEMBERSHIP } from '../../lib/constants';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import { FooterLink } from './FooterLink';

const Footer = () => {
  const footerInfo = useSelector((state) => state.globalSettings.settings?.footer);
  const logo = useSelector((state) => state.globalSettings.settings?.logo);
  const routerLocation = useLocation();
  const dataLayer = useGTMDataLayer();

  const shouldHideBecomeMemberButton =
    !!routerLocation?.pathname?.includes(becomeAMemberPath) ||
    !!routerLocation?.pathname?.includes(becomeAMemberIfYouHaveSmallKidsPath);

  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof footerInfo === 'undefined' || (footerInfo && Object.keys(footerInfo).length === 0)) {
      dispatch(actions.getGlobalSettings.request(''));
    }
  }, [dispatch, footerInfo]);

  const cld = useCloudinary();

  const logoImage =
    (logo && logo.publicId && cld.image(logo.publicId).quality('auto:best').format('svg').toURL()) || undefined;

  const handleBecomeMemberClick: MouseEventHandler<HTMLAnchorElement> = () => {
    dataLayer?.push({
      event: 'footerClick',
      menuPage: routerLocation.pathname,
      menuSectionName: 'footerClick',
      menuItemName: 'Bli medlem',
      menuItemUrl: '/medlemskap',
    });
  };

  return (
    <ThemeProvider theme={themeLight}>
      <S.FooterWrapper data-elastic-exclude>
        <S.FooterContent aria-label="Bunnmeny">
          <S.StyledGrid>
            <S.ExpandedFooter>
              {footerInfo?.footerCol1 && footerInfo.footerCol1.links?.length > 0 && (
                <GridCol s="12" m="12" l="4" xl="3">
                  {footerInfo.footerCol1.header && (
                    <S.StyledHeader variant={TextVariant.Header3}>{footerInfo.footerCol1.header}</S.StyledHeader>
                  )}
                  <S.LinkList>
                    {footerInfo.footerCol1.links.map((link) => (
                      <FooterLink key={`${link.title}-${link.key}`} link={link} />
                    ))}
                  </S.LinkList>
                </GridCol>
              )}
              {footerInfo?.footerCol2 && footerInfo.footerCol2.links?.length > 0 && (
                <GridCol s="12" m="12" l="4" xl="3">
                  {footerInfo.footerCol2.header && (
                    <S.StyledHeader variant={TextVariant.Header3}>{footerInfo.footerCol2.header}</S.StyledHeader>
                  )}
                  <S.LinkList>
                    {footerInfo.footerCol2.links.map((link) => (
                      <FooterLink key={`${link.title}-${link.key}`} link={link} />
                    ))}
                  </S.LinkList>
                </GridCol>
              )}
              {footerInfo?.footerCol3 && footerInfo.footerCol3.links?.length > 0 && (
                <GridCol s="12" m="12" l="4" xl="3">
                  {footerInfo.footerCol3.header && (
                    <S.StyledHeader variant={TextVariant.Header3}>{footerInfo.footerCol3.header}</S.StyledHeader>
                  )}
                  <S.LinkList>
                    {footerInfo.footerCol3.links.map((link) => (
                      <FooterLink key={`${link.title}-${link.key}`} link={link} />
                    ))}
                  </S.LinkList>
                </GridCol>
              )}
              <S.HideableResponsiveGridCol $isHidden={shouldHideBecomeMemberButton} s="12" m="12" l="12" xl="3">
                <S.MemberWrapper>
                  <Link to={`${MEMBERSHIP}`} onClick={handleBecomeMemberClick}>
                    <Button type="button" variant="secondary" size="large">
                      Bli medlem
                    </Button>
                  </Link>
                </S.MemberWrapper>
              </S.HideableResponsiveGridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Bottom>
                  <S.NAFLogo to="/">
                    <img src={logoImage} alt="Naf logo" />
                  </S.NAFLogo>
                  {(footerInfo?.address || footerInfo?.postalAddress) && (
                    <S.AddressInfo>
                      <Text tag="p" variant={TextVariant.ArticleTextSubHeader}>
                        Norges Automobil-Forbund
                      </Text>
                      <Text tag="p" variant={TextVariant.ArticleTextSubHeader}>
                        {footerInfo?.address && <span>{`${footerInfo?.address}`}</span>}
                        {footerInfo?.postalAddress && <span>{`, ${footerInfo?.postalAddress}`}</span>}
                      </Text>
                    </S.AddressInfo>
                  )}
                  <S.Icons>
                    {footerInfo?.facebook && (
                      <S.IconWrapper href={footerInfo?.facebook} aria-label="NAF på Facebook" target="_blank">
                        <FacebookIcon />
                      </S.IconWrapper>
                    )}
                    {footerInfo?.instagram && (
                      <S.IconWrapper href={footerInfo?.instagram} aria-label="NAF på Instagram" target="_blank">
                        <InstagramIcon />
                      </S.IconWrapper>
                    )}
                    {footerInfo?.linkedIn && (
                      <S.IconWrapper href={footerInfo?.linkedIn} aria-label="NAF på LinkedIn" target="_blank">
                        <LinkedInIcon />
                      </S.IconWrapper>
                    )}
                    {footerInfo?.twitter && (
                      <S.IconWrapper href={footerInfo?.twitter} aria-label="NAF på Twitter" target="_blank">
                        <S.SizedIcon>
                          <TwitterIcon />
                        </S.SizedIcon>
                      </S.IconWrapper>
                    )}
                  </S.Icons>
                </S.Bottom>
              </GridCol>
            </S.ExpandedFooter>
            <S.MobileFooter>
              <S.HideableGridCol $isHidden={shouldHideBecomeMemberButton} s="12" m="12" l="12" xl="12">
                <S.MemberWrapper>
                  <S.MobileButtonWrapper>
                    <Link to={`${MEMBERSHIP}`} onClick={handleBecomeMemberClick}>
                      <Button type="button" variant="secondary" size="large">
                        Bli medlem
                      </Button>
                    </Link>
                  </S.MobileButtonWrapper>
                </S.MemberWrapper>
              </S.HideableGridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                {footerInfo?.footerCol1 && footerInfo.footerCol1.links?.length > 0 && (
                  <S.StyledAccordion label={footerInfo.footerCol1.header} size="large">
                    <S.LinkList>
                      {footerInfo.footerCol1.links.map((link) => (
                        <FooterLink key={`${link.title}-${link.key}`} link={link} />
                      ))}
                    </S.LinkList>
                  </S.StyledAccordion>
                )}
                {footerInfo?.footerCol2 && footerInfo.footerCol2.links?.length > 0 && (
                  <S.StyledAccordion label={footerInfo.footerCol2.header} size="large">
                    <S.LinkList>
                      {footerInfo.footerCol2.links.map((link) => (
                        <FooterLink key={`${link.title}-${link.key}`} link={link} />
                      ))}
                    </S.LinkList>
                  </S.StyledAccordion>
                )}
                {footerInfo?.footerCol3 && footerInfo.footerCol3.links?.length > 0 && (
                  <S.StyledAccordion label={footerInfo.footerCol3.header} size="large">
                    <S.LinkList>
                      {footerInfo.footerCol3.links.map((link) => (
                        <FooterLink key={`${link.title}-${link.key}`} link={link} />
                      ))}
                    </S.LinkList>
                  </S.StyledAccordion>
                )}
              </GridCol>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.Bottom>
                  {(footerInfo?.address || footerInfo?.postalAddress) && (
                    <S.AddressInfo>
                      <Text tag="p" variant={TextVariant.ArticleTextSubHeader}>
                        Norges Automobil-Forbund
                      </Text>
                      <Text tag="p" variant={TextVariant.ArticleTextSubHeader}>
                        {footerInfo?.address && <span>{`${footerInfo?.address}`}</span>}
                        {footerInfo?.postalAddress && <span>{`, ${footerInfo?.postalAddress}`}</span>}
                      </Text>
                    </S.AddressInfo>
                  )}
                  <S.NAFLogo to="/">
                    <img src={logoImage} alt="Naf logo" />
                  </S.NAFLogo>
                  <S.Icons>
                    {footerInfo?.facebook && (
                      <S.IconWrapper href={footerInfo?.facebook} aria-label="NAF på Facebook" target="_blank">
                        <FacebookIcon />
                      </S.IconWrapper>
                    )}
                    {footerInfo?.instagram && (
                      <S.IconWrapper href={footerInfo?.instagram} aria-label="NAF på Instagram" target="_blank">
                        <InstagramIcon />
                      </S.IconWrapper>
                    )}
                    {footerInfo?.linkedIn && (
                      <S.IconWrapper href={footerInfo?.linkedIn} aria-label="NAF på LinkedIn" target="_blank">
                        <LinkedInIcon />
                      </S.IconWrapper>
                    )}
                    {footerInfo?.twitter && (
                      <S.IconWrapper href={footerInfo?.twitter} aria-label="NAF på Twitter" target="_blank">
                        <S.SizedIcon>
                          <TwitterIcon />
                        </S.SizedIcon>
                      </S.IconWrapper>
                    )}
                  </S.Icons>
                </S.Bottom>
              </GridCol>
            </S.MobileFooter>
            <GridRow>
              <GridCol s="12" m="12" l="12" xl="12">
                <S.PrivacyInfoWrapper>
                  <Text tag="p" variant={TextVariant.BodyText}>
                    &copy; Norges Automobil-Forbund
                  </Text>
                  {footerInfo?.footerCol4 && footerInfo.footerCol4.links?.length > 0 && (
                    <S.LinkList>
                      {footerInfo.footerCol4.links.map((link) => (
                        <FooterLink key={`${link.title}-${link.key}`} link={link} />
                      ))}
                    </S.LinkList>
                  )}
                </S.PrivacyInfoWrapper>
              </GridCol>
            </GridRow>
          </S.StyledGrid>
        </S.FooterContent>
      </S.FooterWrapper>
    </ThemeProvider>
  );
};

export default Footer;
