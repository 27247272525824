import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints, themeLight, themeVisualBreak, spacing, themeJustSuper } from '@naf/theme';
import { Content } from '../../../layout/Layout';
import { BlockContainer } from '../BlockContainer';
import { ThemeContextAction } from '../../../../lib/ThemeContext';
import { ScrollableTextBlocksWithBackgroundImagesType } from '../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';
import { ParallaxItem } from './ParallaxItem';
import { ParallaxItemImage } from './ParallaxItemImage';
import { ThemeVariants } from '../../../../../../types/themes';

export const ScrollableTextBlocksWithBackgroundImages = ({
  block,
}: {
  block: ScrollableTextBlocksWithBackgroundImagesType;
}) => {
  const setContextTheme = useContext(ThemeContextAction);
  const [bgImage, setBgImage] = useState('');
  const [bgImageHeight, setBgImageHeight] = useState('100vh');

  // Container for images that shows in the background of the text
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Section refs for wrapper where text is shown above images
  const sectionsRef = useRef<HTMLDivElement | null>(null);
  const sectionEndRef = useRef<HTMLDivElement | null>(null);
  const sectionStartRef = useRef<HTMLDivElement | null>(null);
  const freezeTriggerRef = useRef<HTMLDivElement | null>(null);
  const userHasScrolled = useRef(false);

  useEffect(() => {
    if (
      containerRef?.current &&
      sectionsRef?.current &&
      sectionEndRef.current &&
      sectionStartRef.current &&
      freezeTriggerRef.current &&
      typeof window !== 'undefined'
    ) {
      const sections = [...sectionsRef.current.getElementsByClassName('sectionText')];
      const sectionsList = sectionsRef.current.getElementsByClassName('section');
      const lastSection = sectionsList.length > 0 ? sectionsList[sectionsList.length - 1] : undefined;

      const imagesList = [...containerRef.current.getElementsByClassName('imageWrapper')];
      const firstImage = imagesList?.length > 0 && imagesList[0].getElementsByTagName('img');
      const backgroundLayers = lastSection && lastSection.getElementsByClassName('backgroundLayer');
      const lastBackgroundLayer =
        backgroundLayers && backgroundLayers.length > 0 ? backgroundLayers[backgroundLayers.length - 1] : undefined;
      let previousScrollY = window.scrollY;

      // Container where frozen images are displayed
      const imageContainer = containerRef.current;

      // These gets information on images to display and height info used in calculating correct triggers
      const firstImageHeight = firstImage && firstImage.length > 0 ? firstImage[0].height : 0;
      const dataImageId = sections[sections.length - 1]?.getAttribute('data-image-id');
      const backgroundImg = dataImageId
        ? containerRef.current.getElementsByClassName(`image_${dataImageId}`)
        : undefined;

      // Changes images and theme in imagecontainer
      const changeImage = (element: Element) => {
        if (!element || !imagesList || imagesList?.length < 1) return false;
        const wrapperId = element.getAttribute('data-image-id');
        imagesList.map((imageWrapper) => {
          if (imageWrapper.id === `${block._key}_${wrapperId}`) {
            imageWrapper.classList.add('show-image');
          } else {
            imageWrapper.classList.remove('show-image');
          }
          return false;
        });

        // Change background/theme if it is set

        const elementBackground = element.getAttribute('data-bg');
        if (elementBackground && elementBackground === ThemeVariants.VisualBreak) {
          setContextTheme(themeVisualBreak);
        }
        if (elementBackground && elementBackground === ThemeVariants.Standard) {
          setContextTheme(themeLight);
        }
        if (elementBackground && elementBackground === ThemeVariants.JustSuper) {
          setContextTheme(themeJustSuper);
        }
        return false;
      };

      // Sets image container in frozen mode (fixed) and clear backgroundimage from last section
      const triggerFreezeEffect = () => {
        if (!imageContainer) return;
        if (imageContainer.classList.contains('hidden')) imageContainer.classList.remove('hidden');
        if (!imageContainer.classList.contains('freeze')) imageContainer.classList.add('freeze');
        setBgImage('');
      };

      const changeParallaxEffect = () => {
        if (!containerRef?.current || !sectionStartRef?.current || !lastSection) return;

        // These are used for calculating when to release freeze effect top and bottom
        const start = sectionStartRef.current.getBoundingClientRect();
        const end = lastSection.getBoundingClientRect();

        // These are used for calculating correct margins and such on triggers to release freeze effect correctly.
        const marginWidth = window.innerWidth < parseInt(breakpoints.l, 10) ? 48 : 96;
        const imgReleaseTriggerTop = window.innerHeight + Math.round((window.innerHeight - firstImageHeight) / 2);
        const imgReleaseTriggerBottom =
          backgroundImg && backgroundImg[0].clientHeight < window.innerHeight
            ? window.innerHeight - Math.round((window.innerHeight - backgroundImg[0].clientHeight) / 2)
            : Math.round(window.innerHeight - marginWidth);

        // Unfreeze images on scrolling up to top startline
        if (start.top >= imgReleaseTriggerTop && previousScrollY > window.scrollY) {
          if (imageContainer.classList.contains('freeze')) imageContainer.classList.remove('freeze');
          if (imageContainer.classList.contains('hidden')) imageContainer.classList.remove('hidden');
        }

        // Switch from frozen image to background image on last section being reached
        if (end.bottom <= imgReleaseTriggerBottom) {
          if (backgroundImg && backgroundImg?.length > 0) {
            setBgImage(backgroundImg[0].getAttribute('src') || '');
            // Calculate correct height on container for background image, to keep the scroll experience smooth for the user when freeze effect releases
            const bgImgHeight =
              backgroundImg[0].clientHeight > window.innerHeight
                ? Math.round(window.innerHeight - marginWidth)
                : backgroundImg[0].clientHeight;
            setBgImageHeight(`${bgImgHeight}px`);
          }
          containerRef.current?.classList.add('hidden');
        }
      };

      // Set initial image
      if (sections && sections.length > 0 && !userHasScrolled.current && imagesList?.length > 0) {
        const wrapperId = sections[0].getAttribute('data-image-id');
        imagesList.map((imageWrapper) => {
          if (imageWrapper.id === `${block._key}_${wrapperId}`) {
            imageWrapper.classList.add('show-image');
          }
          return false;
        });
      }

      // SectionObserver for textelements in Parallax. This will cause images to change when text reaches a certain point in viewport.
      const sectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const imageFreezeEffectEnabled = containerRef?.current?.classList.contains('hidden');
              // Set intersection element wrapper image id to ensure correct image if user refreshes page after scrolling
              if (
                entry.target.classList.contains('sectionText') &&
                !userHasScrolled.current &&
                !imageFreezeEffectEnabled
              ) {
                changeImage(entry.target);
                changeParallaxEffect();
              }
              // As text elements are in view, change image
              if (entry.target.classList.contains('sectionText') && !imageFreezeEffectEnabled) {
                changeImage(entry.target);
              }
            }
          });
        },
        {
          rootMargin: '-25% 0% -25% 0%',
          threshold: 0,
        },
      );

      // Add text observer, will cause images to change as user scrolls
      sections.map((item) => {
        sectionObserver.observe(item);
        return false;
      });

      // Image Observer for freezing image container, only runs on first image hitting center of screen when scrolling from top down
      const imageFixedObserver = new IntersectionObserver(
        (entries) => {
          const [{ isIntersecting }] = entries;
          if (isIntersecting) {
            triggerFreezeEffect();
          }
          return false;
        },
        {
          rootMargin: '0px 0px -50% 0px',
          threshold: 0.5, // half of item height
        },
      );

      // Image Observer for freezing image container, only runs when scrolling up from bottom and hitting on background image on last section is centered in viewport
      const lastSectionObserver = new IntersectionObserver(
        (entries) => {
          const [{ isIntersecting }] = entries;
          if (isIntersecting) {
            triggerFreezeEffect();
          }
          return false;
        },
        {
          rootMargin: '-50% 0px 0% 0px',
          threshold: 0.5, // half of item height
        },
      );

      // Add image observers, these are the ones that will cause freeze effect on image container when triggered
      imageFixedObserver.observe(freezeTriggerRef.current);
      if (lastBackgroundLayer) lastSectionObserver.observe(lastBackgroundLayer);

      const listen = () => {
        userHasScrolled.current = true;
        changeParallaxEffect();
        previousScrollY = window.scrollY;
      };

      window.addEventListener('scroll', listen);

      return () => {
        window.removeEventListener('scroll', listen);
        sectionObserver.disconnect();
        imageFixedObserver.disconnect();
        lastSectionObserver.disconnect();
      };
    }
    return undefined;
  }, [setContextTheme, block._key]);

  return (
    <StyledBlockContainer className="ScrollableTextBlocksWithBackgroundImages">
      {block?.textAndImageBlocks?.length > 0 && (
        <StyledContent>
          <ImageContainer ref={containerRef} id="imageContainer">
            <ImageFixedTriggerContainer ref={freezeTriggerRef} className="ImageFixedTriggerContainer">
              {block.textAndImageBlocks.map((item) => (
                <ParallaxItemImage item={item} sectionKey={block._key} key={`${item._key}_${item.title}`} />
              ))}
            </ImageFixedTriggerContainer>
          </ImageContainer>
          <SectionStart id="sectionStart" ref={sectionStartRef} />
          <Sections ref={sectionsRef}>
            {block.textAndImageBlocks.map((item) => (
              <ParallaxItem
                item={item}
                bgImage1={bgImage}
                bgImage1Height={bgImageHeight}
                key={`${item._key}_${item.title}`}
              />
            ))}
          </Sections>
          <SectionEnd id="sectionEnd" ref={sectionEndRef} />
        </StyledContent>
      )}
    </StyledBlockContainer>
  );
};

const ImageContainer = styled.div`
  width: calc(100vw - 96px);
  max-width: 1120px;
  max-height: 100vh;
  position: relative;
  margin-top: -100vh;
  text-align: center;
  overflow: hidden;
  display: flex;
  // justify-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  top: 0;
  &.freeze {
    position: fixed;
    margin-top: 0;
    z-index: -5;
    align-items: center;
    justify-content: center;
    img {
      visibility: visible;
    }
  }
  &.hidden {
    margin-top: -100vh;
    img {
      visibility: hidden;
    }
  }

  @media (max-width: ${breakpoints.l}) {
    width: calc(100vw - 48px);
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100vw;
    height: 100vh;
  }
`;

const ImageFixedTriggerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  @media (max-width: ${breakpoints.l}) {
    max-height: calc(100vh - 48px);
  }

  @media (max-width: ${breakpoints.s}) {
    max-height: 100vh;
  }
`;

const StyledBlockContainer = styled(BlockContainer)`
  margin-top: 0;
  margin-bottom: ${spacing.space48};
  display: flex;
  p + & {
    margin-top: ${spacing.space48};
  }
`;

const Sections = styled.div``;

const StyledContent = styled(Content)`
  margin-top: 100vh;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: ${breakpoints.s}) {
    padding-left: 0;
    padding-right: 0;
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
  }
`;

// Hidden sections used for triggering frozen/normal scroll
const SectionStart = styled.div`
  height: 0;
  width: 100vw;
`;

const SectionEnd = styled.div`
  height: 0;
  width: 100vw;
`;
