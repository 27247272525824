import React, { useState } from 'react';
import styled from 'styled-components';
import { TabPanel } from '@naf/tabs';
import { TextVariant } from '@naf/text';
import { CarMeta } from '../../../../types/carModelType';
import { TestTabs } from './TestTabs';
import { formatTestSeason } from './RangeTests';
import { Season } from '../../../../types/ElPrixContextData';
import { InfoBox } from './InfoBox';
import { SectionHeader } from './ModelDetailPage';
import { ChargeTestContent, TestData } from './ChargeTestsContent';

type TestGroup = Record<`${Season}_${string}`, { tests: { [key: string]: TestData } }>;

type Props = Pick<CarMeta, 'chargeTests' | 'cloudinaryImages' | 'rangeTests'> & {
  chargeTestInfoBox: CarMeta['globalContent']['chargeTestInfoBox'];
  slug: string;
};

export const ChargeTests: React.FC<Props> = ({
  chargeTests,
  chargeTestInfoBox,
  cloudinaryImages,
  rangeTests,
  slug,
}) => {
  const testGroups: TestGroup = chargeTests.reduce((acc, chargeTest) => {
    const { season, testDate } = chargeTest.context;
    const testKey = `${season}_${testDate}`;
    const { vehicleId } = chargeTest.vehicleModelReference;

    const { statedConsumptionKWhper100km, statedRangeKm } = rangeTests.find(
      (rangeTest) => rangeTest.vehicleModelReference.vehicleId === vehicleId,
    );

    if (acc[testKey]) {
      acc[testKey] = {
        ...acc[testKey],
        tests: { ...acc[testKey].tests, [vehicleId]: { ...chargeTest, statedRangeKm, statedConsumptionKWhper100km } },
      };
    } else {
      acc[testKey] = {
        tests: { [vehicleId]: { ...chargeTest, statedConsumptionKWhper100km, statedRangeKm } },
      };
    }
    return acc;
  }, {});

  const tabContexts = Object.keys(testGroups)
    .sort((a, b) => {
      const dateA = new Date(a.split('_')[1]).getTime();
      const dateB = new Date(b.split('_')[1]).getTime();
      return dateB - dateA;
    })
    .map((key) => {
      const [season, date] = key.split('_') as [Season, string];
      return {
        key,
        value: formatTestSeason(season, date),
      };
    });

  const [selectedTab] = useState(tabContexts[0]);

  return (
    <>
      <ScrollAnchorTag id="ladetester">
        <SectionHeader variant={TextVariant.Header1} tag="h2">
          Test av lading
        </SectionHeader>
      </ScrollAnchorTag>
      <TestTabs selectedTab={selectedTab} testType="chargeTest" testContexts={tabContexts}>
        <TabPanel item="overview">Oversikt</TabPanel>
        {Object.entries(testGroups).map(([key, { tests }]) => (
          <TabPanel item={key} key={key}>
            <ChargeTestContent slug={slug} tests={tests} sanityImages={cloudinaryImages} />
          </TabPanel>
        ))}
      </TestTabs>
      {chargeTestInfoBox && <InfoBox title={chargeTestInfoBox.title} text={chargeTestInfoBox.text} />}
    </>
  );
};

const ScrollAnchorTag = styled.div`
  scroll-margin: 100px;
`;
