// AppInsights.js
import React, { ReactElement, useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from 'history';
import useSelector from '../redux/typedHooks';

interface Props {
  children?: ReactElement | null;
}

export const AppInsightsContextProvider = ({ children }: Props) => {
  const [appInsights, setAppInsights] = useState<ApplicationInsights | undefined>(undefined);
  const [isAppinsightsLoaded, setIsAppinsightsLoaded] = useState(false);
  const [reactPlugin] = useState(new ReactPlugin());

  const applicationInsightsInstrumentationKey = useSelector((state) => state.application.appInsightsInstrumentationKey);

  useEffect(() => {
    const browserHistory = createBrowserHistory();
    if (reactPlugin && appInsights == null) {
      setAppInsights(
        new ApplicationInsights({
          config: {
            instrumentationKey: applicationInsightsInstrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
              [reactPlugin.identifier]: { history: browserHistory },
            },
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlugin]);

  useEffect(() => {
    if (typeof appInsights !== 'undefined' && appInsights !== null && !isAppinsightsLoaded) {
      appInsights.loadAppInsights();
      setIsAppinsightsLoaded(true);
    }
  }, [appInsights, isAppinsightsLoaded]);

  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};
