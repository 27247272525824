import styled, { css } from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Link } from 'react-router-dom';

export const ProfileMenuWrapper = styled.div<{ $isScrolled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1.125rem;
  transition: all 0.3s ease-in-out;
  margin-right: ${spacing.space8};

  a {
    padding: ${spacing.space4} ${spacing.space8};
    border-radius: ${spacing.space4};

    :hover {
      text-decoration: none;
      background-color: ${nafColor.signature.yellow40};
      border-radius: ${spacing.space4};
    }
  }

  @media (min-width: ${breakpoints.l}) {
    ${({ $isScrolled }) =>
      $isScrolled &&
      css`
        font-size: 1rem;
      `}
  }

  @media (max-width: ${breakpoints.l}) {
    padding-right: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    margin-right: 0;
  }
`;

const TopBarItemStyling = css`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  color: ${nafColor.signature.black} !important;
  border-radius: ${spacing.space4};
  border: 1px solid transparent;

  &:hover {
    text-decoration: none;
    background-color: ${nafColor.signature.yellow40};
  }

  &:focus {
    border: 1px solid black !important;
  }

  span {
    margin: ${spacing.space4} ${spacing.space8};

    @media (max-width: ${breakpoints.m}) {
      margin: ${spacing.space4} ${spacing.space4};
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 0.875rem;
    }
  }

  &.profile-menu {
    margin: 0 -${spacing.space4} 0 0 !important;

    @media (max-width: ${breakpoints.s}) {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${spacing.space16} !important;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: row;
    justify-content: center;
    margin-left: ${spacing.space16} !important;
    margin-right: -${spacing.space4} !important;
    padding: 0 ${spacing.space4} !important;
  }
`;

export const TopBarItem = styled(Link)`
  ${TopBarItemStyling}
`;

export const TopBarItemButton = styled.button`
  ${TopBarItemStyling}
`;

export const LoginButton = styled(Link)`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  color: ${nafColor.signature.black} !important;
  border-radius: ${spacing.space4};
  border: 1px solid transparent;
  margin-right: 8px;

  &:hover {
    text-decoration: none;
    background-color: ${nafColor.signature.yellow40};
  }

  &:focus {
    border: 1px solid black !important;
  }

  span {
    margin: ${spacing.space4} ${spacing.space8};

    @media (max-width: ${breakpoints.m}) {
      margin: ${spacing.space4} ${spacing.space8};
    }

    @media (max-width: ${breakpoints.s}) {
      font-size: 0.875rem;
    }
  }

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${spacing.space16} !important;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: row;
    justify-content: center;
    margin-right: ${spacing.space8} !important;
    padding: 0 ${spacing.space4} !important;
  }
`;

export const LoginText = styled(Text)`
  white-space: nowrap;
  color: ${nafColor.signature.black} !important;

  p {
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: ${breakpoints.s}) {
    font-size: 0.875rem;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.s}) {
    display: flex;
  }
`;
