import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Label } from '@naf/label';
import { Link } from 'react-router-dom';
import { CloudinaryImageType } from '../../../../types/blockTypes';
import { useResponsiveImage } from '../../hooks/useResponsiveImage';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';

type Props = {
  article: {
    id: string;
    name: string;
    ingress: string;
    slug: string;
    isActive: boolean;
    image: CloudinaryImageType;
    step: number;
  };
  size?: 'small' | 'large';
};

const ArticleSeriesItem = forwardRef(({ article, size = 'small' }: Props, ref) => {
  const cldImage = useResponsiveImage({
    imageId: article.image?.publicId,
    quality: 'auto:eco',
  });
  const link = useDocumentUrlWithNode(article);

  return (
    <Wrapper $isActive={article.isActive} ref={ref} to={link}>
      <ImgAndLabel>
        {article.isActive && <StyledLabel text="Du leser nå" variant="spruce" size="small" />}
        <StyledImg src={cldImage?.toURL() || ''} alt={article.image?.alt || article.image?.altText} $size={size} />
      </ImgAndLabel>
      <TextWrapper>
        <StyledText variant={TextVariant.ArticleTextHeader}>{`Del ${article.step}: ${article.name}`}</StyledText>
        {size === 'large' && <Ingress>{article.ingress}</Ingress>}
      </TextWrapper>
    </Wrapper>
  );
});

const StyledText = styled(Text)`
  margin: 0;
`;

const StyledLabel = styled(Label)`
  position: absolute;
  top: -${spacing.space24};
  left: 0;
  white-space: nowrap;
`;

const StyledImg = styled.img<{ $size: 'small' | 'large' }>`
  margin-right: ${spacing.space16};
  width: ${({ $size }) => ($size === 'small' ? '90px' : '160px')};
  height: ${({ $size }) => ($size === 'small' ? '70px' : '120px')};

  @media (max-width: ${breakpoints.m}) {
    width: ${({ $size }) => ($size === 'small' ? '218px' : '160px')};
    height: ${({ $size }) => ($size === 'small' ? '116px' : '120px')};
  }
`;

const ImgAndLabel = styled.div`
  position: relative;
  z-index: 1;
`;

const Wrapper = styled(Link)<{ $isActive: boolean; ref: any }>`
  display: flex;
  margin: ${spacing.space16} 0;
  padding: ${spacing.space16};
  min-width: 340px;
  ${({ $isActive }) => $isActive && `background-color: ${themeLight.background.brand}`};
  text-decoration: none;
  scroll-snap-align: start;
  scroll-snap-type: none;
  scroll-margin: 0 30px;

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    min-width: 240px;
    scroll-margin: 0 16px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Ingress = styled(Text)`
  margin-top: ${spacing.space8};

  @media (max-width: ${breakpoints.s}) {
    display: none;
  }
`;

export default ArticleSeriesItem;
