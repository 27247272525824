import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useWindowResize } from '../../../../hooks/useWindowResize';

import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { TextAndBackgroundImageBlock } from '../../../../../../types/ScrollableTextBlocksWithBackgroundImagesType';

export const ParallaxItemImage = ({ item, sectionKey }: { item: TextAndBackgroundImageBlock; sectionKey: string }) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const [maxHeightMobile, setMaxHeightMobile] = useState('100vh');

  const [image1, setImage1] = useState(
    (item?.backgroundImage?.public_id &&
      cld
        .image(item?.backgroundImage.public_id)
        .resize(
          fill()
            .width(Math.round(1120 / devicePixelRatio))
            .gravity(autoGravity()),
        )
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const setResponsiveImage = useCallback(() => {
    if (typeof window !== 'undefined') {
      const image =
        window.innerWidth < parseInt(breakpoints.s, 10) && item && item.backgroundImageMobile
          ? item.backgroundImageMobile
          : item.backgroundImage;
      if (image && image.public_id) {
        // Check if image is widescreen or portrait
        const isWidescreenImage = image.width && image.height ? image.width > image.height : false;
        // Calculate margins
        const marginWidth = window.innerWidth < parseInt(breakpoints.l, 10) ? 48 : 0;

        if (isWidescreenImage && window.innerWidth > parseInt(breakpoints.s, 10)) {
          let width = Math.round(window.innerWidth - marginWidth);
          // If screen is large, use max 1120 (container) as width
          if (window.innerWidth > parseInt(breakpoints.s, 10)) {
            width = Math.round(1120);
          }
          // If image width is less than viewport width, use image width instead of viewport width
          if (image.width && image.width < window.innerWidth) {
            width = Math.round(image.width - marginWidth);
          }
          setImage1(
            cld
              .image(image.public_id)
              .resize(fill().width(width).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        } else {
          // If image is portrait or mobile view
          const screenRatio = Math.round(window.innerHeight / window.innerWidth);
          let height = screenRatio < 2 ? window.innerHeight : Math.round(0.9 * window.innerHeight);
          setMaxHeightMobile(`${height}px`);
          // Use image height if not small screen
          if (image.height && image.height < window.innerHeight) height = image.height;
          setImage1(
            cld
              .image(image.public_id)
              .resize(fill().height(height).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        }
      }
    }
  }, [cld, devicePixelRatio, item]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  return (
    <ImageWrapper
      className="imageWrapper"
      $imageOriginalWidth={item?.backgroundImage?.width}
      $maxHeightMobile={maxHeightMobile}
      data-image-id={item._key}
      id={`${sectionKey}_${item._key}`}
    >
      {item?.backgroundImage && image1 && (
        <img
          id={`image_${item._key}`}
          className={`image_${item._key}`}
          src={image1.toURL()}
          alt={item?.backgroundImage.alt || item.title}
        />
      )}
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div<{ $imageOriginalWidth?: number; $maxHeightMobile?: string }>`
  opacity: 0;
  transition: opacity 0.3s linear;
  max-height: calc(100vh - 96px);
  overflow: hidden;
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: ${({ $imageOriginalWidth }) =>
      $imageOriginalWidth && $imageOriginalWidth < 1120 ? `${$imageOriginalWidth}px` : '100%'};
    max-height: 100%;
    @media (max-width: ${breakpoints.s}) {
      max-width: fit-content;
      max-height: ${({ $maxHeightMobile }) => $maxHeightMobile || '100vh'};
    }
  }
  &.show-image {
    opacity: 1;
  }
  @media (max-width: ${breakpoints.l}) {
    max-height: calc(100vh - 48px);
  }

  @media (max-width: ${breakpoints.s}) {
    max-height: 100vh;
  }
`;
