import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing, breakpoints } from '@naf/theme';
import { IconButton } from '@naf/icon-button';
import { Text, TextVariant } from '@naf/text';
import type { SanityBlock } from '@sanity/client';
import { PortableText } from '@portabletext/react';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';

const components = {
  block: ({ children }: { children?: ReactNode }) => children,
};

export const WordReferenceMarks = ({
  value: {
    data: { word, description },
  },
  children,
}: {
  value?: { data: { word: string; slug: string; description: SanityBlock[] } };
  children: ReactNode;
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleKeypress = (event: React.KeyboardEvent, callback: () => void) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      event.stopPropagation();
      callback();
    }
  };

  useOutsideAlerter(wrapperRef, isOpen, () => setIsOpen(false));

  return (
    <Wrapper ref={wrapperRef}>
      <WordWithReference
        tabIndex={0}
        role="button"
        onKeyUp={(e) =>
          handleKeypress(e, () => {
            setIsOpen(true);
          })
        }
        onClick={onClick}
      >
        {children}
      </WordWithReference>
      <DictionaryBox $isOpen={isOpen}>
        <StyledIconButton icon="close" onClick={() => handleClose()} ariaLabel="Lukk" />
        <DictionaryBoxContent>
          <Header tag="span" variant={TextVariant.Header3}>
            {word}
          </Header>
          <PortableText components={components} value={description} />
        </DictionaryBoxContent>
      </DictionaryBox>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: inline-block;
`;

const WordWithReference = styled.span`
  padding: 4px 4px 3px 4px;
  cursor: pointer;
  border-bottom: 1px dashed #000;
  :hover {
    background-color: ${nafColor.signature.yellow20};
    border: 1px dashed #000;
    padding: 3px 3px;
  }
`;

const DictionaryBox = styled.span<{ $isOpen: boolean }>`
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: 528px;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  background-color: ${nafColor.signature.yellow20};
  border-radius: 2px;
  margin-top: 2px;
  z-index: 1;
  @media (max-width: ${breakpoints.m}) {
    right: 24px;
    left: 24px;
    min-width: 200px;
  }
`;

const DictionaryBoxContent = styled.span`
  padding: ${spacing.space32};
  display: flex;
  flex-direction: column;
`;

const Header = styled(Text)`
  font-size: 1.5rem;
  line-height: 30px;
  margin-bottom: 16px !important;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  background: none;
  &:hover {
    background: ${nafColor.signature.yellow};
    border: none;
  }
`;
