import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

interface MapProps extends google.maps.MapOptions {
  zoom: number;
  center: google.maps.LatLngLiteral;
  style: { [key: string]: string | number };
  children?: React.ReactNode;
  mapId?: string;
  directions?: google.maps.DirectionsResult;
  mapOptions?: (google.maps.DirectionsRendererOptions & { animatedPathStroke?: string }) | null;
  markers?: (google.maps.MarkerOptions & { infoWindowContent?: string })[];
  animate?: boolean;
}

export const Map: React.FC<MapProps> = ({ style, children, directions, mapOptions, markers, animate, ...options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [animatedDirections, setAnimatedDirections] = useState<google.maps.DirectionsResult | null>(null);
  const directionsRender = useMemo(() => new google.maps.DirectionsRenderer(), []);

  const placeMarkers = useMemo(
    () => () => {
      if (markers) {
        markers.forEach(({ infoWindowContent, ...markerOptions }) => {
          const marker = new google.maps.Marker({
            ...markerOptions,
            map,
          });

          if (infoWindowContent) {
            const infoWindow = new google.maps.InfoWindow({
              content: infoWindowContent,
            });
            marker.addListener('click', () => {
              infoWindow.open({ anchor: marker, map });
            });
          }
        });
      }
    },
    [map, markers],
  );

  const runAnimation = () => {
    if (!animate) return;

    // `decodePath` can take `overview_polyline` as an input
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const path = google.maps.geometry.encoding.decodePath(animatedDirections?.routes[0].overview_polyline);

    let i = 0;
    const drawspeed = 4;
    const id = setInterval(() => {
      // Add markers after animation is finished
      if (i + 1 === path.length) {
        placeMarkers();
        clearInterval(id);
      }

      const p = [path[i], path[i + 1]];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _ = new google.maps.Polyline({
        path: p,
        strokeColor: mapOptions?.animatedPathStroke,
        map,
        zIndex: i + 2,
      });
      i += 1;
    }, drawspeed);
  };

  useEffect(() => {
    if (ref.current && !map && typeof window !== 'undefined') {
      setMap(
        new window.google.maps.Map(ref.current, {
          ...options,
          streetViewControl: false,
          mapTypeControl: false,
          mapId: '8df6d3a1ba0dd900',
        }),
      );
    }
  }, [ref, map, options]);

  useEffect(() => {
    if (directions && directionsRender && map) {
      directionsRender.setMap(map);
      directionsRender.setDirections(directions);
      setAnimatedDirections(directions);

      if (mapOptions) {
        directionsRender.setOptions(
          animate ? mapOptions : { ...mapOptions, polylineOptions: { strokeColor: mapOptions.animatedPathStroke } },
        );
      }
      if (!animate) {
        placeMarkers();
      }
    }
  }, [animate, map, mapOptions, placeMarkers, directions, directionsRender]);

  return (
    <Waypoint onEnter={runAnimation} topOffset="200px">
      <div style={style} ref={ref} id="map">
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && map) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return React.cloneElement(child, { map });
          }
          return null;
        })}
      </div>
    </Waypoint>
  );
};
