import React, { useEffect, useState } from 'react';
import { limitFill } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { CloudinaryImageType, CloudinaryVideoType } from '../../../../types/blockTypes';
import { useCloudinary } from '../../hooks/useCloudinary';
import { CloudinaryVideoPlayerOptions } from '../../hooks/useCloudinaryWithVideoPlayer';

export interface VideoPlayerProps {
  video: CloudinaryVideoType;
  onVideoPlay?: () => any;
  onVideoStart?: () => any;
  isAccessibleForUser?: boolean;
  videoPlayerOptions?: CloudinaryVideoPlayerOptions;
  image?: CloudinaryImageType;
}

export const DynamicVideoPlayer = ({
  video,
  onVideoStart,
  onVideoPlay,
  isAccessibleForUser = true,
  videoPlayerOptions,
  image,
}: VideoPlayerProps) => {
  const [DynamicallyImportedVideoPlayer, setDynamicallyImportedVideoPlayer] = useState<any>(null);
  const cld = useCloudinary();

  const importVideoPlayer = async () => {
    const VideoPlayerModule = await import('./VideoPlayerModule');
    setDynamicallyImportedVideoPlayer(VideoPlayerModule);
  };

  const poster = video?.publicId && cld?.video(`${video.publicId}.jpg`).resize(limitFill().width(795));

  useEffect(() => {
    importVideoPlayer();
  }, []);

  if (DynamicallyImportedVideoPlayer && video && isAccessibleForUser) {
    return (
      <DynamicallyImportedVideoPlayer.VideoPlayer
        videoPlayerOptions={videoPlayerOptions}
        video={video}
        onVideoStart={onVideoStart}
        onVideoPlay={onVideoPlay}
        image={image}
      />
    );
  }
  return poster ? (
    <AdvancedImage
      style={{ maxWidth: '100%' }}
      cldImg={poster}
      plugins={[
        lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
        responsive({ steps: 200 }),
        placeholder({ mode: 'blur' }),
      ]}
    />
  ) : null;
};
