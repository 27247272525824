import { useEffect } from 'react';
import useSelector from '../redux/typedHooks';
import { TagManager } from '../components/gtm-module';
import { setCookie } from '../lib/cookies';
import { GTMEnrichedWindow } from '../../../types/GTM/GTMEnrichedWindow';

const tagManagerArgs = {
  gtmId: 'GTM-TP54XT',
  dataLayer: undefined,
  dataLayerName: 'dataLayer' as keyof Window,
};

export const useGTMInitializer = () => {
  const gtmMeasurementId = useSelector((state) => state.application.gtmMeasurementId);

  useEffect(() => {
    if (gtmMeasurementId) {
      if (typeof window !== 'undefined') {
        TagManager.initialize(tagManagerArgs);
        (window as unknown as GTMEnrichedWindow).gtag = function useGtmInitializePushArguments() {
          // eslint-disable-next-line prefer-rest-params
          (window as unknown as GTMEnrichedWindow).dataLayer.push(arguments);
        };
        if (typeof (window as unknown as GTMEnrichedWindow).gtag !== 'undefined') {
          (window as unknown as GTMEnrichedWindow).gtag('get', gtmMeasurementId, 'session_id', (sessionId) => {
            setCookie('ga_session_id', sessionId, 365, 'None', true, '.naf.no');
          });
        }
      }
    }
  }, [gtmMeasurementId]);
};
