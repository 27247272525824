import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { isIE } from 'react-device-detect';
import { DialogIE } from '@naf/dialog';
import { Button } from '@naf/button';
import { breakpoints, spacing } from '@naf/theme';
import { ButtonLink } from '@naf/button-link';

import { nafColor } from '@nafcore/theme';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import SchemaOrganization from '../structured-data/SchemaOrganization';
import favicon from '../../../assets/favicon.ico';

import webmanifest from '../../../assets/manifest.webmanifest?url';
import { useSendGTMEventOnce } from '../../hooks/useSendGTMEventOnce';
import Paywall from '../paywall/Paywall';
import { UserAccessibility } from '../../../../types/articleType';
import { useOpenInCms } from '../../hooks/useOpenInCms';
import ErrorBoundary from '../error-component/ErrorBoundary';
import { InternalSearchMetaDataType } from '../../../../types/InternalSearchMetaDataType';
import { InternalSearchMetaData } from './InternalSearchMetaData';
import { BackToTop } from '../BackToTop/BackToTop';

interface Props {
  title?: string;
  description?: string;
  children?: any;
  url?: string;
  imgUrl?: string | undefined;
  isHiddenFromSearch?: boolean;
  gtmArgs?: {
    page_type: string;
    pageCategory: string;
    contentId: string;
    author?: string;
    headline?: string;
    publishDate?: string;
    daysSincePublish?: number;
    articleWords?: string;
    isPaywall?: boolean;
    viewType?: string;
  };
  seoDescription?: string;
  seoTitle?: string;
  userAccessibility?: UserAccessibility;
  isElBilGuide?: boolean;
  canonicalUrl?: string;
  internalSearchMetaData: InternalSearchMetaDataType;
  backToTopButton?: boolean;
}

const RenderIEDialog = () => {
  const [open, setOpen] = useState(true);
  return (
    <DialogIE
      isOpen={open}
      handleClose={() => setOpen(false)}
      button={<Button onClick={() => setOpen(false)}>Ok</Button>}
    >
      Vi støtter ikke Internet Explorer. Bytt til en mer moderne nettleser som Chrome, Firefox, Safari, Opera eller Edge
      for å få en god og sikker opplevelse av nettsidene våre.
    </DialogIE>
  );
};

export const LayoutWithMainContent = ({
  children,
  title,
  description,
  url,
  imgUrl,
  isHiddenFromSearch,
  gtmArgs,
  seoDescription,
  seoTitle,
  userAccessibility,
  isElBilGuide,
  canonicalUrl,
  internalSearchMetaData,
  backToTopButton,
}: Props) => (
  <Layout
    title={title}
    description={description}
    url={url}
    imgUrl={imgUrl}
    isHiddenFromSearch={isHiddenFromSearch}
    gtmArgs={gtmArgs}
    seoDescription={seoDescription}
    seoTitle={seoTitle}
    canonicalUrl={canonicalUrl}
    internalSearchMetaData={internalSearchMetaData}
  >
    {backToTopButton && <BackToTop />}
    <ContentContainer isElBilGuide={isElBilGuide}>
      <MainContent isElBilGuide={isElBilGuide}>{children}</MainContent>
    </ContentContainer>
    {userAccessibility && !userAccessibility?.isAccessibleForUser && <Paywall userAccessibility={userAccessibility} />}
  </Layout>
);

export const Layout = ({
  title = 'NAF - Norges Automobil-Forbund | NAF',
  description = '',
  children,
  url = '',
  imgUrl = DEFAULT_IMG_URL,
  gtmArgs,
  isHiddenFromSearch,
  seoTitle,
  seoDescription,
  canonicalUrl,
  internalSearchMetaData,
}: Omit<Props, 'userAccessibility'>) => {
  let eventArgs = gtmArgs;
  if (gtmArgs && !(gtmArgs.page_type === 'ArticlePage' || gtmArgs.page_type === 'CarTestPage')) {
    eventArgs = {
      ...gtmArgs,
      author: null,
      headline: null,
      publishDate: null,
      daysSincePublish: null,
      isPaywall: null,
      viewType: null,
    };
  }
  useSendGTMEventOnce(eventArgs || undefined, [gtmArgs]);

  useOpenInCms();

  // TODO: Add Helmet
  return (
    <Wrapper>
      <Helmet>
        {seoTitle || title ? <title>{`${seoTitle || title} | NAF`}</title> : null}
        <base href="/" />
        <html lang="no" dir="ltr" />
        <meta name="language" content="nb_NO" />
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="og:type" content="website" />
        <meta name="theme-color" content={nafColor.signature.white} />
        <meta name="description" content={seoDescription || description} key="description" />
        {isHiddenFromSearch ? <meta name="robots" content="noindex" /> : null}
        <meta name="facebook-domain-verification" content="pgedkcgsbgqob438evbkr89u1c2qy5" />
        <meta property="og:title" content={`${title} | NAF`} key="og:title" />
        {url && <meta property="og:url" content={url} key="og:url" />}
        <meta property="og:description" content={description} key="og:description" />
        <meta property="og:image" content={imgUrl} />
        <meta property="og:image:secure_url" content={imgUrl} />
        <link rel="icon" href={favicon} />
        <link rel="manifest" href={webmanifest} crossOrigin="use-credentials" />
        <link href="https://use.typekit.net/" rel="preconnect" />
        <link rel="apple-touch-icon" href={favicon} />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {InternalSearchMetaData({
          internalSearchMetaData,
          title: seoTitle || title,
          description: seoDescription || description,
        })}
      </Helmet>
      <SchemaOrganization />
      <ErrorBoundary>{children}</ErrorBoundary>
    </Wrapper>
  );
};

export const MainContent = ({
  children,
  isElBilGuide,
  className,
}: {
  children: ReactNode;
  isElBilGuide?: boolean;
  className?: string;
}) => {
  const mainContentRef = useRef<HTMLDivElement>(null);

  const [showIEDialog, setShowIEDialog] = useState(false);
  useEffect(() => {
    setShowIEDialog(isIE);
  }, []);

  return (
    <Content isElBilGuide={isElBilGuide} className={className}>
      <StyledButtonLink
        data-elastic-exclude
        href="#mainContent"
        text="Gå til hovedinnhold"
        icon="arrow-down"
        onClick={(e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          if (mainContentRef.current) mainContentRef.current.focus();
        }}
      />
      {showIEDialog && <RenderIEDialog />}
      <main ref={mainContentRef} id="mainContent">
        {children}
      </main>
    </Content>
  );
};

const ContentContainer = styled.div<{ isElBilGuide?: boolean }>`
  ${({ isElBilGuide }) =>
    isElBilGuide
      ? ''
      : css`
          margin-bottom: ${spacing.space120};
        `}
  min-height: calc(100vh - 480px);
  padding-top: ${spacing.space160};

  @media (max-width: ${breakpoints.l}) {
    padding-top: ${spacing.space120};
  }
  @media (max-width: ${breakpoints.m}) {
    padding-top: ${spacing.space80};
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const StyledButtonLink = styled(ButtonLink)`
  clip: rect(0 0 0 0);
  position: absolute !important;
  height: 1px;
  margin-top: -10px;
  overflow: hidden;
  width: 1px;

  &:focus {
    clip: auto;
    position: relative !important;
    height: auto;
    margin-top: 10px;
    overflow: visible;
    width: auto;
  }
`;

export const MAX_WIDTH = '1216px';

export const Content = styled.div<{ isElBilGuide?: boolean }>`
  box-sizing: border-box;
  max-width: ${({ isElBilGuide }) => (isElBilGuide ? '1920px' : MAX_WIDTH)};
  margin: auto;
  padding: 0 ${({ isElBilGuide }) => (isElBilGuide ? 0 : spacing.space48)};
  flex-direction: column;

  @media (max-width: ${breakpoints.l}) {
    padding: 0 24px;
  }
`;
