import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import { TypedEpic } from '../types';
import { messagesResponse } from '../../../../types/messageType';

// Actions
export enum Actions {
  GET_MESSAGES = 'naf/GET_MESSAGES',
  GET_MESSAGES_SUCCESS = 'naf/GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAIL = 'naf/GET_MESSAGES_FAIL',
  GET_MESSAGES_CANCEL = 'naf/GET_MESSAGES_CANCEL',
}

export interface State {
  messageList: messagesResponse;
  isUpdating: boolean;
  errorState?: any;
}

export const initialState: State = {
  messageList: {
    messages: [],
    customerId: '',
    providerStatuses: null,
  },
  isUpdating: false,
};

export const actions = {
  getMyMessages: createAsyncAction(
    Actions.GET_MESSAGES, // request payload creator
    Actions.GET_MESSAGES_SUCCESS, // success payload creator
    Actions.GET_MESSAGES_FAIL, // failure payload creator
    Actions.GET_MESSAGES_CANCEL, // optional cancel payload creator
  )<[string, { token?: string }], messagesResponse, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getMyMessages.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getMyMessages.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = false;
      draftState.messageList = action.payload;
    }),
  )
  .handleAction(actions.getMyMessages.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMyMessages.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

export const getMyMessagesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMessageHubApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MESSAGES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMyMessages.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<messagesResponse>({
        url: `${apimBaseUrl}/${apimMessageHubApi}/customer/messages?${action.payload}`,
        headers,
        method: 'GET',
      }).pipe(
        map(({ response }) => actions.getMyMessages.success(response)),
        catchError(() =>
          of(
            actions.getMyMessages.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente meldingene dine! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [getMyMessagesEpic];
