import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';
import { CarMeta, CarModelType, CarVariant } from '../../../../types/carModelType';
import { TDateISO } from '../../../../types/DateType';

// Actions
export enum Actions {
  GET_ONE_CAR = 'GET_ONE_CAR',
  GET_ONE_CAR_SUCCESS = 'GET_ONE_CAR_SUCCESS',
  GET_ONE_CAR_FAIL = 'GET_ONE_CAR_FAIL',
  GET_ONE_CAR_CANCEL = 'GET_ONE_CAR_CANCEL',
  GET_CARS = 'GET_CARS',
  GET_CARS_SUCCESS = 'GET_CARS_SUCCESS',
  GET_CARS_FAIL = 'GET_CARS_FAIL',
  GET_CARS_CANCEL = 'GET_CARS_CANCEL',
  GET_MORE_CARS = 'GET_MORE_CARS',
  GET_MORE_CARS_SUCCESS = 'GET_MORE_CARS_SUCCESS',
  GET_MORE_CARS_FAIL = 'GET_MORE_CARS_FAIL',
  GET_MORE_CARS_CANCEL = 'GET_MORE_CARS_CANCEL',
  GET_CAR_MODEL_META = 'GET_CAR_MODEL_META',
  GET_CAR_MODEL_META_SUCCESS = 'GET_CAR_MODEL_META_SUCCESS',
  GET_CAR_MODEL_META_FAIL = 'GET_CAR_MODEL_META_FAIL',
  GET_CAR_MODEL_META_CANCEL = 'GET_CAR_MODEL_META_CANCEL',
}

export interface CarModelStateType {
  data: CarModelType;
  errorState?: Error;
  meta: {
    isUpdating: boolean;
    fetchedAt?: TDateISO;
  };
}

export interface State {
  vehicleCounts: number;
  data: CarModelType['id'][][];
  mappedData: Record<CarModelType['slug'], CarModelStateType>;
  mappedCarVariants: Record<CarVariant['id'], CarVariant>;
  isUpdating: boolean;
  errorState: Error | null;
}

export interface CarResponse {
  totalVehicleModelsInQuery: number;
  vehicleModels: CarModelType[];
}

export const initialState: State = {
  data: [[]],
  mappedData: {},
  mappedCarVariants: {},
  vehicleCounts: 0,
  isUpdating: true,
  errorState: null,
};

export const actions = {
  getOneCar: createAsyncAction(
    Actions.GET_ONE_CAR,
    Actions.GET_ONE_CAR_SUCCESS,
    Actions.GET_ONE_CAR_FAIL,
    Actions.GET_ONE_CAR_CANCEL,
  )<string, [CarResponse, { slug: string }], [Error, { slug: string }], [undefined, { slug: string }]>(),
  getCars: createAsyncAction(
    Actions.GET_CARS, // request payload creator
    Actions.GET_CARS_SUCCESS, // success payload creator
    Actions.GET_CARS_FAIL, // failure payload creator
    Actions.GET_CARS_CANCEL, // optional cancel payload creator
  )<string, CarResponse, Error, undefined>(),
  getMoreCars: createAsyncAction(
    Actions.GET_MORE_CARS, // request payload creator
    Actions.GET_MORE_CARS_SUCCESS, // success payload creator
    Actions.GET_MORE_CARS_FAIL, // failure payload creator
    Actions.GET_MORE_CARS_CANCEL, // optional cancel payload creator
  )<string, CarResponse, Error, undefined>(),
  getCarModelMeta: createAsyncAction(
    Actions.GET_CAR_MODEL_META, // request payload creator
    Actions.GET_CAR_MODEL_META_SUCCESS, // success payload creator
    Actions.GET_CAR_MODEL_META_FAIL, // failure payload creator
    Actions.GET_CAR_MODEL_META_CANCEL, // optional cancel payload creator
  )<
    [string, { slug: string }],
    [CarMeta, { slug: string }],
    [Error, { slug: string }],
    [undefined, { slug: string }]
  >(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getOneCar.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.payload] = { ...draftState.mappedData[action.payload], meta: { isUpdating: true } };
    }),
  )
  .handleAction(actions.getOneCar.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      const mappedModels = action.payload.vehicleModels.reduce(
        (acc, cur) => {
          const currentMeta = draftState.mappedData[cur.slug].data?.meta;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc[cur.slug] = { data: cur, meta: { isUpdating: false, fetchedAt: new Date().toISOString() } };
          if (currentMeta) {
            acc[cur.slug].data = { ...acc[cur.slug].data, meta: currentMeta };
          }
          return acc;
        },
        { ...draftState.mappedData },
      );
      const mappedCarVariants = action.payload.vehicleModels.reduce(
        (acc: Record<CarVariant['id'], CarVariant>, cur) => {
          const carVariantMap = cur.vehicleVariants.reduce((acc2: Record<CarVariant['id'], CarVariant>, cur2) => {
            acc2[cur2.id] = cur2;
            return acc2;
          }, {});
          return { ...acc, ...carVariantMap };
        },
        {},
      );
      if (action.meta.slug) {
        draftState.mappedData[action.meta.slug] = {
          ...draftState.mappedData[action.meta.slug],
          ...mappedModels[action.meta.slug],
        };
        draftState.mappedCarVariants = { ...draftState.mappedCarVariants, ...mappedCarVariants };
        draftState.mappedData[action.meta.slug].meta.isUpdating = false;
      }
    }),
  )
  .handleAction(actions.getOneCar.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      if (action.meta.slug) {
        draftState.mappedData[action.meta.slug].meta.isUpdating = false;
      }
    }),
  )
  .handleAction(actions.getOneCar.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      if (action.meta.slug) {
        draftState.mappedData[action.meta.slug].meta.isUpdating = false;
      }
    }),
  )
  .handleAction(actions.getCars.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getCars.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.data = [action.payload.vehicleModels.map((car) => car.slug)];
      draftState.mappedData = action.payload.vehicleModels.reduce(
        (acc, cur) => {
          const existingData = draftState?.mappedData?.[cur.slug]?.data;
          const fetchedAt = new Date().toISOString();
          if (existingData) {
            acc[cur.slug] = {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              data: { ...cur, ...existingData },
              meta: { isUpdating: false, fetchedAt },
            };
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[cur.slug] = { data: cur, meta: { isUpdating: false, fetchedAt } };
          }
          return acc;
        },
        { ...draftState.mappedData },
      );
      draftState.mappedCarVariants = action.payload.vehicleModels.reduce(
        (acc: Record<CarVariant['id'], CarVariant>, cur) => {
          const carVariantMap = cur.vehicleVariants.reduce((acc2: Record<CarVariant['id'], CarVariant>, cur2) => {
            acc2[cur2.id] = cur2;
            return acc2;
          }, {});
          return { ...acc, ...carVariantMap };
        },
        {},
      );
      draftState.vehicleCounts = action.payload.totalVehicleModelsInQuery;
    }),
  )
  .handleAction(actions.getCars.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getCars.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  )
  .handleAction(actions.getMoreCars.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getMoreCars.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draftState.mappedData = action.payload.vehicleModels.reduce(
        (acc, cur) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc[cur.slug] = { data: cur, meta: { isUpdating: false, fetchedAt: new Date().toISOString() } };
          return acc;
        },
        { ...state.mappedData },
      );
      draftState.mappedCarVariants = action.payload.vehicleModels.reduce(
        (acc: Record<CarVariant['id'], CarVariant>, cur) => {
          const carVariantMap = cur.vehicleVariants.reduce((acc2: Record<CarVariant['id'], CarVariant>, cur2) => {
            acc2[cur2.id] = cur2;
            return acc2;
          }, {});
          return { ...acc, ...carVariantMap };
        },
        {},
      );
      draftState.data = [...draftState.data, action.payload.vehicleModels.map((car) => car.slug)];
    }),
  )
  .handleAction(actions.getMoreCars.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMoreCars.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  )
  .handleAction(actions.getCarModelMeta.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.slug].meta.isUpdating = true;
    }),
  )
  .handleAction(actions.getCarModelMeta.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.slug].meta.isUpdating = false;
      draftState.mappedData[action.meta.slug].data = {
        ...draftState.mappedData[action.meta.slug].data,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        meta: action.payload,
      };
    }),
  )
  .handleAction(actions.getCarModelMeta.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.slug].meta.isUpdating = false;
      draftState.mappedData[action.meta.slug].errorState = action.payload;
    }),
  )
  .handleAction(actions.getCarModelMeta.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.mappedData[action.meta.slug].meta.isUpdating = false;
    }),
  );

export const getOneCarEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimVehicleDataApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_ONE_CAR),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getOneCar.request>[]>,
    switchMap(([action]: { payload: string }[]) =>
      ajax<CarResponse>({
        url: `${apimBaseUrl}/${apimVehicleDataApi}/models?slugs=${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getOneCar.success(response, { slug: action.payload })),
        catchError(() => of(actions.getOneCar.failure(new Boom.Boom('Could not get car'), { slug: action.payload }))),
      ),
    ),
  );
};

const getCarModelMetaEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CAR_MODEL_META),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getCarModelMeta.request>[]
    >,
    mergeMap(([action]: { payload: string; meta: { slug: string } }[]) =>
      ajax<CarMeta>({
        url: `${apimBaseUrl}/${apimNafNoApi}/vehiclemodelarticles/${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getCarModelMeta.success(response, { slug: action.meta.slug })),
        catchError(() =>
          of(actions.getCarModelMeta.failure(new Boom.Boom('Could not enrich car model'), { slug: action.meta.slug })),
        ),
      ),
    ),
  );
};

export const fetchCarsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimVehicleDataApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CARS),
    withLatestFrom(state$) as unknown as OperatorFunction<Action<any>, ActionType<typeof actions.getCars.request>[]>,
    switchMap(([action]: { payload: string }[]) =>
      ajax<CarResponse>({
        url: `${apimBaseUrl}/${apimVehicleDataApi}/models?${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getCars.success(response)),
        catchError(() => of(actions.getCars.failure(new Boom.Boom('Could not get cars')))),
      ),
    ),
  );
};

const fetchMoreCarsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimVehicleDataApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MORE_CARS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMoreCars.request>[]
    >,
    switchMap(([action]: { payload: string }[]) =>
      ajax<CarResponse>({
        url: `${apimBaseUrl}/${apimVehicleDataApi}/models?${action.payload}`,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getMoreCars.success(response)),
        catchError(() => of(actions.getMoreCars.failure(new Boom.Boom('Could not get more cars')))),
      ),
    ),
  );
};

export const epics: TypedEpic[] = [getOneCarEpic, fetchCarsEpic, fetchMoreCarsEpic, getCarModelMetaEpic];
