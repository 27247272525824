import React from 'react';
import { Link } from 'react-router-dom';
import { Lock } from '@styled-icons/material-outlined/Lock';
import { Text, TextVariant } from '@naf/text';
import { useAuth0 } from '@auth0/auth0-react';
import useSelector from '../../redux/typedHooks';
import * as S from './Styles';

export const ProfileMenu = ({ onClick, isScrolled }: { onClick?: () => void; isScrolled?: boolean }) => {
  const isRelative = (path: string) => !/^[a-z][a-z0-9+.-]*:/.test(path);
  const { logout } = useAuth0();
  const profileMenu = useSelector((state) => state.globalSettings.settings?.profileMenu);
  const { membership } = useSelector((state) => state.myMembership?.customerInformation?.data);

  return (
    <S.ProfileMenuList $isScrolled={isScrolled}>
      {profileMenu && profileMenu.length > 0 && (
        <>
          {profileMenu.map((link) => {
            if (link.membersOnly && !membership?.isValid) return null;
            return (
              <li key={link.title}>
                <Text tag="p" variant={TextVariant.BodyText}>
                  {isRelative(link.href) ? (
                    <Link to={link.href} onClick={onClick}>
                      {link.title}
                    </Link>
                  ) : (
                    <a href={link.href} rel="noreferrer">
                      {link.title}
                    </a>
                  )}
                </Text>
              </li>
            );
          })}
        </>
      )}
      <S.ProfileMenuItem>
        <S.StyledButton
          type="button"
          onClick={() => {
            sessionStorage.clear();
            localStorage.setItem('previousUrl', window.location.href);
            logout({ logoutParams: { returnTo: `${window.location.origin}/nafid/logout` } });
          }}
        >
          <Lock size={24} />
          <Text tag="span" variant={TextVariant.BodyText}>
            Logg ut
          </Text>
        </S.StyledButton>
      </S.ProfileMenuItem>
    </S.ProfileMenuList>
  );
};
