import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Logo = styled.img<{ $isScrolled?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  transition: all 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 32px;
      width: 32px;
    `}
`;

export const StyledLink = styled(Link)`
  outline: none;
`;
