import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { AppInsightsErrorBoundary, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import FinderIllustration from '../icons/DynamicallyImportedFinderIllustration';

type Props = {
  children: any; // need any here since AppInsightsErrorBoundary types children as React.ReactElement, which does not support multiple React Nodes
};

const ErrorBoundary = ({ children }: Props) => {
  const appInsight = useAppInsightsContext();
  return (
    <AppInsightsErrorBoundary appInsights={appInsight} onError={ErrorComponent}>
      {children}
    </AppInsightsErrorBoundary>
  );
};

export const ErrorComponent = () => (
  <ErrorWrapper>
    <FinderIllustration />
    <Text variant={TextVariant.Header3} tag="h2">
      Ooops, noe gikk dessverre galt
    </Text>
    <Text variant={TextVariant.ArticleText}>Vi klarte ikke finne veien. Vennligst forsøk igjen senere.</Text>
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  text-align: center;
  padding-top: ${spacing.space48};
  padding-bottom: ${spacing.space64};
  p {
    margin-top: ${spacing.space8};
  }
  h2 {
    margin-bottom: ${spacing.space8};
  }
  div {
    margin: 0 auto;
  }
`;

export default ErrorBoundary;
