import React from 'react';
import NafBirthdayDesktop from '../../../../../../assets/decorations/nafBirthday/NafBirthdayDesktop.svg';
import NafBirthdayMobile from '../../../../../../assets/decorations/nafBirthday/NafBirthdayMobile.svg';
import * as S from './Styles';

export const NafBirthday = ({ isScrolled }: { isScrolled?: boolean }) => (
  <>
    <S.DesktopWrapper isScrolled={isScrolled}>
      <NafBirthdayDesktop />
    </S.DesktopWrapper>
    <S.MobileWrapper>
      <NafBirthdayMobile />
    </S.MobileWrapper>
  </>
);
