import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BreadcrumbItem, Breadcrumbs } from '@naf/breadcrumb';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import routeConfig from '../../config/routeConfig';
import useSelector from '../../redux/typedHooks';
import { StyledLink } from '../styled-link/StyledLink';
import { BreadcrumbLoader } from '../skeleton-loaders/BreadcrumbLoader';

interface BreadCrumpProps {
  paddingTop?: boolean;
  className?: string;
}

export const BreadCrumb = ({ paddingTop = true, className }: BreadCrumpProps) => {
  const generatedCrumbs = useBreadcrumbs(routeConfig);
  const categoriesState = useSelector((state) => state.sitestructure?.mappedCategories);
  const documentState = useSelector((state) => state.documents?.mappedData);

  // Do not simplify this - it caused the webapp to break in production
  const [shouldShowBreadcrumbs, setShouldShowBreadcrumbs] = useState(false);
  useEffect(() => {
    setShouldShowBreadcrumbs(generatedCrumbs?.length > 1);
  }, [generatedCrumbs]);
  // Do not simplify this - end

  return shouldShowBreadcrumbs ? (
    <StyledBreadcrumbs $paddingTop={paddingTop} className={className}>
      {generatedCrumbs.map(({ match, breadcrumb, key, location }) => {
        if (breadcrumb && React.isValidElement(breadcrumb)) {
          // Do some lookups to get prettier names, fallback to slug/url
          const bcSlug = breadcrumb.props.children && breadcrumb.props.children.toLowerCase();
          const catName = bcSlug && categoriesState && categoriesState[bcSlug];
          const documentName = bcSlug && documentState && documentState[bcSlug];
          const slugName = breadcrumb.props.children ? breadcrumb.props.children.replace(/-/g, ' ') : '';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const bcName = catName?.data?.name || documentName?.data?.name || documentName?.data?.title || slugName;

          return location.pathname !== match.url ? (
            <BreadcrumbItem key={`bcitem_${key}`}>
              <StyledLink to={match.url}>{bcName}</StyledLink>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={`bcitem_${key}`}>{bcName}</BreadcrumbItem>
          );
        }
        return null;
      })}
    </StyledBreadcrumbs>
  ) : (
    <StyledSkeleton $paddingTop={paddingTop}>{!generatedCrumbs && <BreadcrumbLoader />}</StyledSkeleton>
  );
};

const StyledSkeleton = styled.div<{ $paddingTop?: boolean }>`
  padding-top: ${(props) => (props.$paddingTop ? '24px' : '0')};
`;

const StyledBreadcrumbs = styled(Breadcrumbs)<{ $paddingTop?: boolean }>`
  padding-top: ${(props) => (props.$paddingTop ? '24px' : '0')};
`;

export default BreadCrumb;
