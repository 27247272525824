import { Text } from '@naf/text';
import { spacing, breakpoints } from '@naf/theme';
import styled from 'styled-components';

// negative margin top to counter margin-bottom in layout.tsx ContentContainer
// and to place paywall on top of article picture
const Wrapper = styled.div`
  margin-top: -320px;
  width: 100%;
  box-shadow: 0px -20px 100px 10px rgba(0, 0, 0, 0.25);
  clip-path: inset(-200px 0 0 0);
  padding: ${spacing.space80} 0;
  background-color: white;
  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space48} 0;
  }
`;

const Header = styled(Text)`
  display: flex;
  justify-content: center;
  margin-top: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin: ${spacing.space8};
  }
`;

const TextWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  margin: ${spacing.space24};
`;

export default {
  Wrapper,
  Header,
  ButtonWrapper,
  TextWrapper,
};
