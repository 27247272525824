import { fromEvent, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { useEffect } from 'react';

export const useWindowResize = (cb: () => any): void => {
  useEffect(() => {
    const event = fromEvent(window, 'resize').pipe(debounce(() => timer(100)));

    const subscription = event.subscribe(cb);

    return () => subscription.unsubscribe();
  });
};
