import React from 'react';
import * as S from './Styles';
import { Logo } from '../LogoDecorations/Logo';

const LogoArea = ({ isScrolled, onNavClick }: { isScrolled?: boolean; onNavClick: () => void }) => (
  <S.LogoArea>
    <Logo isScrolled={isScrolled} onNavClick={onNavClick} />
    <S.RoadAssistance href="tel:+47-08505" aria-label="Ring oss på 08 505" $isScrolled={isScrolled}>
      <span>Ring oss</span>
      <strong>08 505</strong>
    </S.RoadAssistance>
    <S.SeparatorLine $isScrolled={isScrolled} />
  </S.LogoArea>
);

export default LogoArea;
